<template>
  <div class="card blog">
    <div class="card-body" v-if="post">
      <div class="d-flex align-items-center">
        <button class="btn btn-link me-2" @click="router.push('/blog')">
          <img src="@/assets/images/icons/arrow.svg" class="back-icon" />
        </button>
        <h2 class="mb-0">{{ post.title }}</h2>
      </div>
      <div class="blog-block text-start mt-4">
        <img
          :src="post.image || require('@/assets/images/logo_info.svg')"
          class="blog-block__mainimage"
        />
        <p v-html="post.content" class="mt-4"></p>
        <p class="date">{{ formatDate(post.created_at) }}</p>
        <button class="black-button" @click="router.push('/blog')">
          Все новости
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import moment from "moment";
import MarketplaceService from "@/services/marketplace";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "Post",
  setup() {
    const post = ref(null);
    const router = useRouter();
    const route = useRoute();

    const formatDate = (date) => {
      return moment(date).format("hh:mm DD.MM.YYYY");
    };

    onMounted(() => {
      MarketplaceService.getPost(route.params.id).then((res) => {
        post.value = res;
      });
    });
    return { post, formatDate, router };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/Blog.scss";
</style>
