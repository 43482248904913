import axios, { AxiosInstance } from "axios";

// Создаем экземпляр Axios с базовыми настройками
const instance: AxiosInstance = axios.create({
  baseURL: "https://x-global.ru/",
  headers: {
    "Content-Type": "application/json",
  },
});

// Если необходимо, вы можете добавить интерсепторы или другие настройки здесь
// instance.interceptors.request.use(config => {
//   // Добавляем токены или изменяем запросы
//   return config;
// });

// instance.interceptors.response.use(response => {
//   // Обрабатываем успешные ответы
//   return response;
// }, error => {
//   // Обрабатываем ошибки
//   return Promise.reject(error);
// });

export default instance;
