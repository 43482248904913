<template>
  <div class="container counterparties" :key="route.params.id">
    <!-- v-if="company && company !== null" -->
    <div class="loader-container" v-if="isLoading">
      <div class="loader"></div>
    </div>
    <template v-else>
      <div class="d-flex flex-column sub-header mt-4">
        <div class="d-md-flex sub-header__title align-items-center">
          <div class="img d-none"></div>
          <h3 class="ms-md-4 mb-md-0 mb-2">
            {{ company.name || company.fio }}
          </h3>
          <span
            class="status ms-md-4"
            :class="getClass(company.status?.name)"
            >{{ company.status?.name }}</span
          >
          <button
            class="border-button btn-primary ms-auto py-1 mt-md-0 mt-2"
            @click="downloadCompanyInfo"
          >
            Скачать карточку
          </button>

          <template
            v-if="
              isLoggedIn &&
              company.representative &&
              company.representative != currentUser.id
            "
          >
            <button
              class="border-button btn-primary ms-4 py-1"
              @click="addFriend"
              v-if="!isFriend"
            >
              <img src="@/assets/images/add_icon.svg" />

              Добавить в друзья
            </button>
            <div v-else class="ms-4">Добавлен в друзья</div>
          </template>
        </div>
        <div class="d-flex sub-header__navs">
          <ul class="nav nav-tabs w-100 justify-content-between mx-4">
            <li v-for="tab in tabs.slice(0, 9)" :key="tab.id" class="nav-item">
              <a
                :class="{ active: activeTab === tab.id + company.inn }"
                :id="tab.id + '-tab' + company.inn"
                data-bs-toggle="tab"
                :href="'#' + tab.id + company.inn"
                role="tab"
                @click="setActiveTab(tab.id)"
                :aria-controls="'tab-' + tab.id + company.inn"
                :aria-selected="activeTab === tab.id + company.inn"
              >
                {{ tab.label }}
              </a>
            </li>

            <li class="nav-item dropdown" v-if="hiddenItems.length > 0">
              <a
                class="nav-link py-0 px-0 dropdown-toggle"
                href="#"
                role="button"
                id="navbarDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Ещё
              </a>
              <ul class="dropdown-menu">
                <li v-for="(hiddenItem, index) in hiddenItems" :key="index">
                  <a
                    class="dropdown-item"
                    :class="{
                      active: activeTab === hiddenItem.id + company.inn,
                    }"
                    :id="hiddenItem.id + '-tab' + company.inn"
                    data-bs-toggle="tab"
                    :href="'#' + hiddenItem.id + company.inn"
                    role="tab"
                    @click="setActiveTab(hiddenItem.id)"
                    :aria-controls="'tab-' + hiddenItem.id + company.inn"
                    :aria-selected="activeTab === hiddenItem.id + company.inn"
                    >{{ hiddenItem.label }}</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="/marketplace">Маркетплейс</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="row mt-4">
        <div class="tab-content" id="myTabContent">
          <!-- Первый таб -->
          <div
            class="tab-pane fade"
            :class="{
              'show active': activeTab === 'tab-summary' + company.inn,
            }"
            :id="'tab-summary' + company.inn"
            role="tabpanel"
            :aria-labelledby="'summary-tab' + company.inn"
          >
            <Summary
              :company="company"
              :finances="companyFinances"
              :inspections="companyInspections"
              :contracts="companyContracts"
              :casesAmount="companyLegalCases?.length || 0"
              @change-tab="setActiveTab"
            />
          </div>

          <!-- Второй таб -->
          <div
            class="tab-pane fade"
            :id="'tab-arbitration' + company.inn"
            role="tabpanel"
            :aria-labelledby="'arbitration-tab' + company.inn"
          >
            <Arbitration :cases="companyLegalCases" />
          </div>

          <!-- Третий таб -->
          <div
            v-if="companyFinances"
            class="tab-pane fade"
            :id="'tab-finances' + company.inn"
            role="tabpanel"
            :aria-labelledby="'finances-tab' + company.inn"
          >
            <Finances
              :finances="companyFinances"
              :html="company?.html_checko"
            />
          </div>

          <!-- Четвертый таб -->
          <div
            class="tab-pane fade"
            :id="'tab-connections' + company.inn"
            role="tabpanel"
            :aria-labelledby="'connections-tab' + company.inn"
          >
            <Connections :connections="company.LPEC" />
          </div>

          <!-- Пятый таб -->
          <div
            class="tab-pane fade"
            :id="'tab-trademarks' + company.inn"
            role="tabpanel"
            :aria-labelledby="'trademarks-tab' + company.inn"
          >
            <Trademarks :trademarks="company.trademarks" />
          </div>

          <!-- Шестой таб -->
          <div
            class="tab-pane fade"
            :id="'tab-founders' + company.inn"
            role="tabpanel"
            :aria-labelledby="'founders-tab' + company.inn"
          >
            <Founders :founders="companyFounders" />
          </div>

          <!-- Седьмой таб -->
          <div
            class="tab-pane fade"
            :id="'tab-reliability' + company.inn"
            role="tabpanel"
            :aria-labelledby="'reliability-tab' + company.inn"
          >
            <Analyze :company="company" v-if="company.html_checko" />
          </div>

          <!-- Восьмой таб -->
          <div
            class="tab-pane fade"
            :id="'tab-debts' + company.inn"
            role="tabpanel"
            :aria-labelledby="'debts-tab' + company.inn"
          >
            <Debts />
          </div>

          <!-- Девятый таб -->
          <div
            class="tab-pane fade"
            :id="'tab-sanctions' + company.inn"
            role="tabpanel"
            :aria-labelledby="'sanctions-tab' + company.inn"
          >
            <Sanctions
              :hasSanctions="company.sanctions"
              :sanctions_countries="company.country_sanctions"
            />
          </div>

          <!-- Десятый таб -->
          <div
            class="tab-pane fade"
            :id="'tab-leasing' + company.inn"
            role="tabpanel"
            :aria-labelledby="'leasing-tab' + company.inn"
          >
            <Leasing />
          </div>

          <!-- Одиннадцатый таб -->
          <div
            class="tab-pane fade"
            :id="'tab-inspections' + company.inn"
            role="tabpanel"
            :aria-labelledby="'inspections-tab' + company.inn"
          >
            <Inspections :inspections="companyInspections" />
          </div>

          <!-- Двенадцаты таб -->
          <div
            class="tab-pane fade"
            :id="'tab-history' + company.inn"
            role="tabpanel"
            :aria-labelledby="'history-tab' + company.inn"
          >
            <History />
          </div>

          <div
            class="tab-pane fade"
            :id="'tab-details' + company.inn"
            role="tabpanel"
            :aria-labelledby="'details-tab' + company.inn"
          >
            <Details :company="company" />
          </div>

          <div
            class="tab-pane fade"
            :id="'tab-okved' + company.inn"
            role="tabpanel"
            :aria-labelledby="'okved-tab' + company.inn"
          >
            <Okved :company="company" />
          </div>

          <div
            class="tab-pane fade"
            :id="'tab-branches' + company.inn"
            role="tabpanel"
            :aria-labelledby="'branches-tab' + company.inn"
          >
            <Branches
              :branches="
                company.division && company.division?.length
                  ? company?.division[0]?.filials
                  : []
              "
            />
          </div>

          <div
            class="tab-pane fade"
            :id="'tab-licenses' + company.inn"
            role="tabpanel"
            :aria-labelledby="'licenses-tab' + company.inn"
          >
            <Licenses :licenses="company.licenses" />
          </div>

          <div
            class="tab-pane fade"
            :id="'tab-licenses' + company.inn"
            role="tabpanel"
            :aria-labelledby="'licenses-tab' + company.inn"
          >
            <Contracts :contracts="companyContracts" v-if="companyContracts" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Summary from "../../components/CounterParties/Summary.vue";
import Connections from "../../components/CounterParties/Connections.vue";
import Analyze from "../../components/CounterParties/Analyze.vue";
import Founders from "../../components/CounterParties/Founders.vue";
import Finances from "../../components/CounterParties/Finances.vue";
import Arbitration from "@/components/CounterParties/Arbitration.vue";
import Sanctions from "@/components/CounterParties/Sanctions.vue";
import Debts from "@/components/CounterParties/Debts.vue";
import Leasing from "@/components/CounterParties/Leasing.vue";
import History from "@/components/CounterParties/History.vue";
import Trademarks from "@/components/CounterParties/Trademarks.vue";
import Okved from "@/components/CounterParties/Okved.vue";
import Details from "@/components/CounterParties/Details.vue";
import Licenses from "@/components/CounterParties/Licenses.vue";
import Contracts from "@/components/CounterParties/Contracts.vue";

import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Tab, Dropdown } from "bootstrap";
import Branches from "@/components/CounterParties/Branches.vue";
import Inspections from "@/components/CounterParties/Inspections.vue";
import CompanyService from "@/services/company";
import * as XLSX from "xlsx"; // Импортируем библиотеку XLSX

export default defineComponent({
  name: "CounterParties",
  components: {
    Summary,
    Connections,
    Analyze,
    Founders,
    Finances,
    Arbitration,
    Contracts,
    Sanctions,
    Licenses,
    Branches,
    Inspections,
    Debts,
    Leasing,
    History,
    Trademarks,
    Okved,
    Details,
  },
  setup() {
    const tabs = [
      { id: "tab-summary", label: "Сводка", component: Summary },
      { id: "tab-arbitration", label: "Арбитраж", component: Arbitration },
      { id: "tab-finances", label: "Финансы", component: Finances },
      { id: "tab-connections", label: "Связи", component: Connections },
      { id: "tab-trademarks", label: "Товарные знаки", component: Trademarks },
      { id: "tab-founders", label: "Учредители", component: Founders },
      { id: "tab-reliability", label: "Надежность", component: Analyze },
      { id: "tab-debts", label: "Долги", component: Debts },
      { id: "tab-sanctions", label: "Санкции", component: Sanctions },
      { id: "tab-leasing", label: "Лизинг", component: Leasing },
      { id: "tab-inspections", label: "Проверки", component: Analyze },
      { id: "tab-history", label: "Истории", component: History },
      { id: "tab-details", label: "Реквизиты", component: Details },
      { id: "tab-okved", label: "Виды деятельности", component: Okved },
      { id: "tab-licenses", label: "Лицензии", component: Licenses },
      { id: "tab-branches", label: "Филиалы", component: Branches },
    ];
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(false);
    const isFriend = ref(false);
    const company = ref("");
    const companyFinances = ref([]);
    const companyFounders = ref([]);
    const companyLegalCases = ref([]);
    const companyContracts = ref([]);
    const companyInspections = ref([]);
    const isLoggedIn = computed(() => store.state.auth.status.loggedIn);
    const activeTab = ref("tab-summary" + route.params.id);
    const currentUser = computed(() => store.state.user.user);

    const getClass = (status) => {
      if (status === "Действует") {
        return "active";
      } else if (status === "Не действует") {
        return "inactive";
      } else {
        return "liquidated";
      }
    };

    function setActiveTab(tabId) {
      activeTab.value = tabId + company.value.inn;
      const tabTrigger = new Tab(
        document.querySelector(`#${tabId}-tab${company.value.inn}`)
      );
      tabTrigger.show();
      // Закрываем дропдаун при клике
      const dropdownElement = document.getElementById("navbarDropdown");
      const dropdown = Dropdown.getInstance(dropdownElement);
      if (dropdown) {
        dropdown.hide(); // Программно закрываем дропдаун
      }
    }

    // Управление состоянием dropdown
    const isDropdownOpen = ref(false);
    const hiddenItems = computed(() => tabs.slice(9));

    function toggleDropdown() {
      isDropdownOpen.value = !isDropdownOpen.value;
    }

    const fetchData = async (id) => {
      isLoading.value = true;
      if (id) {
        try {
          let companyRes = await store.dispatch("companies/getByInn", id);
          companyFinances.value = await store.dispatch(
            "companies/getFinances",
            id
          );
          await sortFinances();
          companyLegalCases.value = await store.dispatch(
            "companies/getLegalCases",
            id
          );
          companyContracts.value = await store.dispatch(
            "companies/getContracts",
            id
          );
          companyInspections.value = await store.dispatch(
            "companies/getInspections",
            id
          );
          if (isLoggedIn.value) {
            CompanyService.getFriends().then((res) => {
              res.map((item) => {
                if (item.friend.id == company.value.representative) {
                  isFriend.value = true;
                }
              });
            });
          }

          if (!companyRes) {
            companyRes = await store.dispatch("companies/getByIP", id);
            // isLoading.value = false;
          }
          company.value = companyRes;
          setActiveTab("tab-summary");
          if (companyRes.founders && companyRes.founders?.length) {
            companyFounders.value =
              [...companyRes.founders[0]?.PP, ...companyRes.founders[0]?.LP] ||
              [];
          } else if (
            companyRes.founder_organizations &&
            companyRes.founder_organizations.length
          ) {
            companyFounders.value =
              companyRes.founder_organizations[0]?.PP || [];
          }
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        } finally {
          isLoading.value = false;
        }
      } else {
        console.error("ID не найден в URL");
        isLoading.value = false;
      }
    };

    const addFriend = () => {
      console.log(company.value);
      const form = ref({
        friend: company.value.representative,
      });
      console.log(form);
      CompanyService.addFriend(form.value).then(() => {
        isFriend.value = true;
        alert("Пользватель добавлен в друзья");
      });
    };

    const sortFinances = () => {
      companyFinances.value.finances_years?.sort(
        (a, b) => Number(a.year) - Number(b.year)
      );
    };

    // Функция для скачивания файла
    const downloadCompanyInfo = () => {
      // Добавляем общую информацию о компании (название, ИНН, адрес и т.д.)
      const companyInfo = [
        ["Полное наименование", company.value.name_full],
        ["Сокращенное наименование", company.value.name],
        ["Юридический адрес", company.value.legal_adress.adress],
        ["Email", company.value.contacts.email[0] || "-"],
        ["Телефон/факс", company.value.contacts.email[0] || "-"],
        ["ИНН/КПП", company.value.inn],
        ["ОГРН", company.value.ogrn],
        ["ОКПО", company.value.okpo?.code || company.value.okpo],
        ["ОКАТО", company.value.okato?.code],
        ["ОКТМО", company.value.oktmo?.code],
        ["ОКОГУ", company.value.okogu?.code],
        ["ОКФС", company.value.okfs?.code],
        ["ОКОПФ", company.value.okopf?.code],
        ["Директор", company.value.directors[0]?.fio],
      ];

      // Преобразуем данные в лист Excel
      const worksheet = XLSX.utils.aoa_to_sheet(companyInfo);
      const rowHeights = new Array(companyInfo.length).fill({ hpt: 40 });
      worksheet["!rows"] = rowHeights;
      // Настройка ширины колонок
      worksheet["!cols"] = [
        { wch: 40 }, // ширина первой колонки
        { wch: 60 }, // ширина второй колонки
      ];

      // Применение стиля к заголовку
      worksheet["A1"].s = {
        font: { bold: true, sz: 16 },
        alignment: { horizontal: "center" },
      };
      // Применение жирного текста для первого столбца
      for (let i = 3; i <= companyInfo.length + 1; i++) {
        const cell = `A${i}`;
        if (worksheet[cell]) {
          worksheet[cell].s = {
            font: { bold: true }, // Делает текст жирным
            alignment: { wrapText: true }, // Авто-перенос текста
          };
        }
      }
      // Применение авто-переноса текста
      for (let cell in worksheet) {
        if (cell[0] !== "!") {
          // Исключаем специальные параметры, такие как "!ref"
          worksheet[cell].s = { alignment: { wrapText: true } };
        }
      }
      worksheet["!ref"] = `A1:B15`; // Заменяем жесткое значение на динамическое

      // Создаём новую книгу Excel
      const workbook = XLSX.utils.book_new();
      // Добавляем лист в книгу
      XLSX.utils.book_append_sheet(workbook, worksheet, "Company Info");

      // Сохраняем файл
      XLSX.writeFile(workbook, "Карточка_" + company.value.name + ".xlsx");
    };
    onMounted(() => {
      const id = route.params.id;
      fetchData(id);
    });
    watch(
      () => route.params.id,
      (newId) => {
        fetchData(newId);
      },
      { immediate: true }
    );

    return {
      company,
      companyFounders,
      companyFinances,
      companyLegalCases,
      companyContracts,
      companyInspections,
      tabs,
      hiddenItems,
      isDropdownOpen,
      route,
      activeTab,
      isLoggedIn,
      isFriend,
      isLoading,
      currentUser,
      setActiveTab,
      getClass,
      toggleDropdown,
      addFriend,
      downloadCompanyInfo,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/counterparties/Summary.scss";
</style>
