<template>
  <div class="profile orders-container">
    <div class="card profile-info full-height">
      <div class="card-body">
        <h4>Заказы</h4>
        <div class="filter">
          <select class="form-select" v-model="selectedStatus">
            <option value="all">Все</option>
            <option value="pending">Ожидается</option>
            <option value="in_progress">В пути</option>
            <option value="not_paid">Не оплачен</option>
            <option value="paid">Оплачен</option>
            <option value="completed">Выполнен</option>
          </select>
        </div>
        <div class="d-flex flex-md-row flex-column mb-4">
          <button
            class="d-flex"
            @click="selectMode('myOrders')"
            :class="mode === 'myOrders' ? 'black-button' : 'grey-button'"
          >
            Покупаю
            <!-- <div class="count ms-2">({{ myOrders.length }})</div> -->
          </button>
          <button
            class="d-flex ms-md-4 mt-md-0 mt-4"
            @click="selectMode('orders')"
            :class="mode === 'orders' ? 'black-button' : 'grey-button'"
          >
            Продаю
            <!-- <div class="count ms-2">({{ orders.length }})</div> -->
          </button>
        </div>
        <div class="row products-container" v-if="mode === 'myOrders'">
          <template v-for="order in filteredMyOrders" :key="order.id">
            <NewProductCard
              v-for="basket in JSON.parse(order.basket)"
              :key="basket.id"
              :product="basket.product"
              :order="order"
            />
          </template>
        </div>
        <div class="row products-container" v-else-if="mode === 'orders'">
          <template v-for="order in filteredOrders" :key="order.id">
            <NewProductCard
              v-for="basket in JSON.parse(order.basket)"
              :key="basket.id"
              :product="basket.product"
              :order="order"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import MarketplaceService from "@/services/marketplace";
import moment from "moment";
import NewProductCard from "@/components/Profile/NewProductCard.vue";

export default defineComponent({
  name: "OrdersPage",
  components: {
    NewProductCard,
  },
  setup() {
    const selectedStatus = ref("all");

    const orders = ref([]);
    const myOrders = ref([]);
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(false);
    const mode = ref("myOrders");
    const loggedIn = computed(() => store.state.auth.status.loggedIn);

    const getOrders = async () => {
      isLoading.value = true;
      MarketplaceService.getSellerOrders().then((res) => {
        isLoading.value = false;
        orders.value = res;
      });
    };

    const getMyOrders = async () => {
      isLoading.value = true;
      MarketplaceService.getMyOrders().then((res) => {
        isLoading.value = false;
        myOrders.value = res;
      });
    };

    const selectMode = (select) => {
      mode.value = select;
      if (select === "orders") {
        getOrders();
      }
      if (select === "myOrders") {
        getMyOrders();
      }
    };

    onMounted(async () => {
      if (!loggedIn.value) {
        router.push("/");
      } else {
        getOrders();
        getMyOrders();

        isLoading.value = true;
      }
    });

    const formatDate = (date) => {
      return moment(date).format("DD.MM.YYYY");
    };

    const formatPrice = (price) => {
      return new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
      }).format(price);
    };

    const statusClass = (status) => {
      switch (status) {
        case "in_progress":
          return "status-in-progress";
        case "not_paid":
          return "status-not-paid";
        case "completed":
          return "status-completed";
        default:
          return "";
      }
    };

    const statusLabel = (status) => {
      switch (status) {
        case "pending":
          return "В пути";
        case "not_paid":
          return "Не оплачен";
        case "completed":
          return "Выполнен";
        default:
          return "";
      }
    };

    const filteredOrders = computed(() => {
      if (selectedStatus.value === "all") {
        return orders.value;
      }
      if (selectedStatus.value === "not_paid") {
        return orders.value.filter((order) => order.paid === false);
      }
      if (selectedStatus.value === "paid") {
        return orders.value.filter((order) => order.paid === true);
      }
      return orders.value.filter(
        (order) => order.status === selectedStatus.value
      );
    });

    const filteredMyOrders = computed(() => {
      if (selectedStatus.value === "all") {
        return myOrders.value;
      }
      if (selectedStatus.value === "not_paid") {
        return myOrders.value.filter((order) => order.paid === false);
      }
      if (selectedStatus.value === "paid") {
        return myOrders.value.filter((order) => order.paid === true);
      }
      return myOrders.value.filter(
        (order) => order.status === selectedStatus.value
      );
    });

    return {
      selectedStatus,
      filteredOrders,
      filteredMyOrders,
      orders,
      myOrders,
      mode,
      formatDate,
      formatPrice,
      statusClass,
      statusLabel,
      selectMode,
    };
  },
});
</script>

<style langs="scss" scoped>
.products-container {
  max-height: calc(100vh - 25rem);
  overflow-y: auto;
  overflow-x: hidden;
  gap: 1.5rem;
}
</style>
