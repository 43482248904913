<template>
  <div class="profile cart">
    <div class="card profile-info full-height">
      <div class="card-body">
        <h2>Карточка товара</h2>
        <button class="grey-button my-4" @click="addProduct()">
          Создать карточку
        </button>
        <h2 class="mt-4">Мои карточки</h2>
        <div class="filter-container">
          <div class="dropdown">
            <button class="grey-button mt-2 mb-2" @click="toggleDropdown">
              Выбрать категории
              <span v-if="selectedCategories.length"
                >({{ selectedCategories.length }})</span
              >
            </button>

            <!-- Выпадающий список -->
            <div v-if="isOpen" class="dropdown-content">
              <div
                v-for="category in categories"
                :key="category.id"
                class="dropdown-item"
              >
                <input
                  type="checkbox"
                  :id="'category-' + category.id"
                  :value="category.id"
                  v-model="selectedCategories"
                />
                <label :for="'category-' + category.id">{{
                  category.name
                }}</label>
              </div>
            </div>
          </div>
          <div class="selected-categories" v-if="selectedCategories.length">
            <span>Выбранные категории:</span>
            <div class="filter-tags">
              <div
                class="filter-tag"
                v-for="category in selectedCategories"
                :key="category"
              >
                {{ getCategoryName(category) }}
              </div>
            </div>
          </div>
        </div>
        <div class="products-container mt-4">
          <div class="product-list row gy-4">
            <NewProductCard
              v-for="(item, index) in filteredProducts"
              :key="index"
              :product="item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import NewProductCard from "../../components/Profile/NewProductCard.vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import MarketplaceService from "../../services/marketplace";
import productCategoriesService from "@/services/products";

export default defineComponent({
  name: "Products",
  components: {
    NewProductCard,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const loggedIn = computed(() => store.state.auth.status.loggedIn);
    const products = ref([]);
    const isLoading = ref(false);

    const route = useRoute();
    const mode = ref("products");
    const categories = ref([]);
    const selectedCategories = ref([]);
    const isOpen = ref(false);

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };

    const getProducts = async () => {
      isLoading.value = true;
      const me = await store.dispatch("user/me");

      MarketplaceService.companyRepresentative(
        me.id,
        route.query.page || 1
      ).then((res) => {
        products.value = res;
        isLoading.value = false;
      });
    };

    const addProduct = () => {
      router.push("/profile/products/add");
    };

    const getCategories = () => {
      productCategoriesService.all().then((res) => {
        categories.value = res;
      });
    };

    const getCategoryName = (categoryId) => {
      const category = categories.value.find((cat) => cat.id === categoryId);
      return category ? category.name : "Неизвестная категория";
    };

    // Фильтрация продуктов по выбранным категориям
    const filteredProducts = computed(() => {
      console.log(selectedCategories.value);
      if (selectedCategories.value.length === 0) {
        return products.value.result; // Показываем все продукты, если категории не выбраны
      }
      return products.value.result.filter((product) =>
        selectedCategories.value.includes(product.category.id)
      );
    });

    onMounted(async () => {
      if (!loggedIn.value) {
        router.push("/");
      } else {
        getProducts();
        getCategories();
        isLoading.value = true;
      }
    });

    const selectMode = (select) => {
      mode.value = select;
      getProducts();
    };

    watch(route, () => {
      getProducts();
    });

    return {
      products,
      filteredProducts,
      selectedCategories,
      categories,
      isOpen,
      selectMode,
      getProducts,
      addProduct,
      getCategoryName,
      toggleDropdown,
    };
  },
});
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 5px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 200px;
  border: 1px solid #ccc;
  z-index: 1;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.dropdown-item label {
  margin-left: 5px;
}

.selected-categories {
  .filter-tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .filter-tag {
    background-color: #f1f1f1;
    padding: 5px 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 5px;

    button {
      background: none;
      border: none;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
