import api from "./api";
import TokenService from "./token";
import router from "../router";
import store from "../store";

// Определяем типы для данных авторизации и регистрации
interface LoginData {
  email: string;
  password: string;
  rememberMe: boolean;
}

interface RegisterData {
  full_name: string;
  email: string;
  password: string;
}

interface RegistrationResponse {
  data: any;
}

interface ErrorResponse {
  response: {
    data: {
      email: string[];
    };
  };
}

class AuthService {
  // Авторизация
  async login({ email, password, rememberMe }: LoginData): Promise<any> {
    try {
      const res = await api.post("/auth/jwt/create", { email, password });
      if (res.data.access) {
        TokenService.setUser(res.data);
        if (rememberMe) {
          TokenService.setTokensInLocalStorage(
            res.data.access,
            res.data.refresh
          );
        } else {
          TokenService.setTokensInSessionStorage(
            res.data.access,
            res.data.refresh
          );
        }
      }
      return res.data;
    } catch (error) {
      // Обработка ошибок
      console.error("Login error:", error);
      throw error;
    }
  }

  isTokenExpired(token: string): boolean {
    try {
      // Split the token into its three parts (header, payload, signature)
      const tokenParts = token.split(".");

      if (tokenParts.length !== 3) {
        return true; // Invalid token structure
      }

      // Decode the payload (which is the second part of the token)
      const base64Url = tokenParts[1];

      // Replace invalid base64 characters (URL-safe base64)
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

      // Decode the base64 string into a JSON object
      const decodedPayload = JSON.parse(window.atob(base64));

      // Check if the token contains an expiration time (exp)
      if (!decodedPayload.exp) {
        return true; // No expiration time in the token
      }

      // Get the current time in seconds since the epoch (Unix time)
      const currentTime = Math.floor(Date.now() / 1000);

      // Compare the expiration time with the current time
      return decodedPayload.exp < currentTime;
    } catch (error) {
      console.error("Error decoding token:", error);
      return true; // If any error occurs, treat the token as expired
    }
  }

  async autoLogin(): Promise<any> {
    const accessToken =
      TokenService.getLocalAccessToken() ||
      TokenService.getSessionAccessToken();

    if (!accessToken) {
      console.log("Нет токена для автологина");
      return Promise.reject("No token found");
    }

    try {
      const user = await api.get("/auth/users/me", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // store.dispatch("user/me", user.data);
      return user.data;
    } catch (error) {
      const refreshToken =
        TokenService.getLocalRefreshToken() ||
        TokenService.getSessionRefreshToken();

      if (!refreshToken) {
        console.log("Рефреш-токен недействителен, перенаправляем на логин");
        this.logout();
        return Promise.reject(error);
      }

      // Обновляем токен
      try {
        const response = await api.post("/auth/jwt/refresh", {
          refresh: refreshToken,
        });

        const newAccessToken = response.data.access;
        if (TokenService.getLocalAccessToken()) {
          TokenService.setTokensInLocalStorage(newAccessToken, refreshToken);
        } else {
          TokenService.setTokensInSessionStorage(newAccessToken, refreshToken);
        }

        const user = await api.get("/auth/users/me", {
          headers: {
            Authorization: `Bearer ${newAccessToken}`,
          },
        });
        store.dispatch("user/me", user.data);
        return user.data;
      } catch (_error) {
        this.logout();
        return Promise.reject(_error);
      }
    }
  }

  // Выход
  logout(): void {
    TokenService.removeUser();
    router.push("/");
  }

  getCSRFToken() {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split("=");
      if (name === "csrftoken") return value;
    }
    return "";
  }
  // Регистрация
  async register({ full_name, email, password }: any): Promise<any> {
    try {
      // Получаем CSRF-токен
      const csrfToken = await this.getCSRFToken();

      // Если CSRF-токен не найден, выбрасываем ошибку
      if (!csrfToken) {
        throw new Error("CSRF token is missing");
      }
      const response = await api.post(
        "/auth/users",
        {
          full_name,
          email,
          password,
        },
        {
          headers: {
            "X-CSRFToken": csrfToken, // Добавляем CSRF-токен в заголовок
          },
        }
      );
      return response.data;
    } catch (error) {
      // Обработка ошибок
      console.error("Registration error:", error);
      throw error;
    }
  }

  async setPassword({ new_password, current_password }: any): Promise<any> {
    try {
      const response = await api.post("/auth/users/set_password/", {
        new_password,
        current_password,
      });

      return response.data;
    } catch (error) {
      // Обработка ошибок
      console.error("Password set error:", error);
      throw error;
    }
  }

  async resetPassword(data: any): Promise<any> {
    try {
      const response = await api.post("/auth/users/reset_password/", data);

      return response.data;
    } catch (error) {
      // Обработка ошибок
      console.error("Password set error:", error);
      throw error;
    }
  }

  async resetPasswordConfirm(data: any): Promise<any> {
    try {
      const response = await api.post(
        "/auth/users/reset_password_confirm/",
        data
      );

      return response.data;
    } catch (error) {
      // Обработка ошибок
      console.error("Password set error:", error);
      throw error;
    }
  }

  // Регистрация с дополнительной обработкой ошибок
  async registration(data: RegisterData): Promise<RegistrationResponse> {
    try {
      const csrfToken = await this.getCSRFToken();

      // Если CSRF-токен не найден, выбрасываем ошибку
      if (!csrfToken) {
        throw new Error("CSRF token is missing");
      }
      const response = await api.post("/auth/users/", data, {
        headers: {
          "X-CSRFToken": csrfToken, // Добавляем CSRF-токен в заголовок
        },
      });
      return response;
    } catch (error) {
      const err = error as ErrorResponse;
      // if (
      //   err.response.data.email[0] ===
      //   "Пользователь with this Электронная почта already exists."
      // ) {
      //   return Promise.reject(error);
      // }
      throw error;
    }
  }
}

export default new AuthService();
