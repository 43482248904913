<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Санкции</h5>
      </div>
      <div class="card-body">
        <div class="accordion mt-4" id="accordionOne">
          <div
            class="accordion-item px-2"
            v-for="(factor, index) in sanctions_factors"
            :key="index"
          >
            <h2 class="accordion-header" :id="'heading' + index">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#collapse' + index"
                aria-expanded="false"
                :aria-controls="'collapse' + index"
              >
                <div class="circle me-3" :class="factor.status"></div>
                {{ factor.title }} :

                <div class="blue-bold ms-2">
                  {{ hasSanctions ? "Да" : "Нет" }}
                </div>
              </button>
            </h2>
            <div
              :id="'collapse' + index"
              class="accordion-collapse collapse"
              :aria-labelledby="'heading' + index"
              data-bs-parent="#accordionOne"
            >
              <div class="accordion-body">
                <template v-if="sanctions_countries?.length">
                  <p
                    v-for="(country, index) in sanctions_countries"
                    :key="index"
                  >
                    {{ country }}
                  </p>
                </template>
                <p v-else>Санкций нет</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Analyze",
  props: {
    hasSanctions: Boolean,
    sanctions_countries: Array,
  },
  setup(props) {
    const isOpen = ref(null);

    const sanctions_factors = ref([
      {
        title: "Санкционные списки",
        status: "danger",
        condition: () => props.hasSanctinos,
        text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      },
      // {
      //   title: "Вид деятельности под воздействием санкций",
      //   status: "decline",
      //   text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      // },
      // {
      //   title: "Участие иностранных лиц",
      //   status: "danger",
      //   text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      // },
      // {
      //   title: "Участие санкционных физических лиц",
      //   status: "decline",
      //   text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      // },
      // {
      //   title: "Связанные организации под санкциями",
      //   status: "success",
      //   text: "В соответствии с данными Единого федерального ресурса сведений о банкротстве (ЕФРСБ), организация не находится в процессе банкротства.",
      // },
    ]);

    const isCollapsed = ref(true);

    function toggleCollapse() {
      isCollapsed.value = !isCollapsed.value;
    }
    return {
      isOpen,
      sanctions_factors,
      toggleCollapse,
    };
  },
});
</script>

<style scoped>
.collapse {
  transition: height 0.35s ease;
}
</style>
