<template>
  <div class="cart profile addProduct">
    <div class="card">
      <div class="card-body">
        <form class="form row" @keydown.enter.prevent>
          <div class="col-md-6">
            <div class="input">
              <label for="name">Название продукта</label>
              <input
                v-model="formData.name"
                type="text"
                class="form-control mt-2"
                id="name"
                placeholder="Введите название"
                required
              />
            </div>
            <div class="input mt-3">
              <label for="description">Описание</label>
              <textarea
                v-model="formData.description"
                type="text"
                class="form-control mt-2"
                rows="5"
                id="description"
                placeholder="Введите описание"
                required
              />
            </div>
            <div class="input images mt-3">
              <label for="inputGroupFile01">Фото товара</label>
              <input
                ref="fileInput"
                @change="onFileChange($event)"
                type="file"
                class="form-control d-none"
                style="height: fit-content"
                id="inputGroupFile01"
                multiple
                :disabled="productImages?.length >= 7"
              />
              <div
                class="selectImage mt-2"
                v-if="productImages?.length < 8"
                @click="triggerFileUpload"
              >
                <img src="@/assets/images/icons/image.png" />
              </div>
              <div class="images-selected mt-4" v-if="productImages?.length">
                <div
                  class="images-selected__block"
                  v-for="(item, index) in productImages"
                  :key="index"
                >
                  <img
                    class="close"
                    src="@/assets/images/icons/close.svg"
                    @click="deleteImg(index)"
                  />
                  <img
                    :src="item.image ? item.image : item"
                    width="100"
                    height="100"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-row">
              <label for="flexSwitchCheckDefault">В наличии</label>
              <input
                v-model="formData.in_stock"
                type="checkbox"
                class="ms-2"
                role="switch"
                id="flexSwitchCheckDefault"
                placeholder=""
              />
            </div>
            <div class="input-row mt-3">
              <label for="flexSwitchCheckDefault">Цена включает налоги</label>
              <input
                v-model="formData.price_with_taxes"
                type="checkbox"
                class="ms-2"
                role="switch"
                id="flexSwitchCheckDefault"
                placeholder=""
              />
            </div>
            <div class="input tags mt-3">
              <label for="tags">Теги</label>
              <div class="tag-input">
                <div class="tags-container">
                  <span
                    v-for="(tag, index) in formTags"
                    :key="index"
                    class="tag"
                  >
                    {{ tag.name || tag }}
                    <button
                      type="button"
                      class="remove-tag"
                      @click="removeTag(index)"
                    >
                      &times;
                    </button>
                  </span>
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="newTag"
                    @keyup.enter="addTag"
                    placeholder="Добавьте тег"
                  />
                </div>
              </div>
            </div>
            <div class="input mt-3" v-if="productCategories?.length">
              <label for="floatingSelect">Категория</label>
              <select
                v-model="formData.category"
                class="form-select mt-2"
                id="floatingSelect"
              >
                <option disabled value="">Выберите категорию</option>
                <option
                  v-for="(item, index) in productCategories"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="input mt-3">
              <label for="floatingSelect">Компания</label>
              <select
                v-model="formData.company"
                class="form-select mt-2"
                id="floatingSelect"
              >
                <option disabled value="">Выберите компанию</option>
                <option
                  v-for="(item, index) in productCompanies"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name_full }}
                </option>
              </select>
            </div>

            <!-- <div class="input mt-3">
              <label for="code">Код продукта</label>
              <input
                v-model="formData.product_code"
                type="text"
                class="form-control mt-2"
                id="code"
                placeholder="Введите код"
              />
            </div> -->
            <!-- <div class="input mt-3">
              <label for="sku">SKU продукта</label>
              <input
                v-model="formData.sku_article"
                type="text"
                class="form-control mt-2"
                id="sku"
                placeholder="Введите SKU"
                required
              />
            </div> -->
            <div class="input mt-3">
              <label for="price">Стоимость</label>
              <input
                v-model="formData.price"
                type="number"
                class="form-control mt-2"
                id="price"
                placeholder="Введите стоимость"
                required
              />
            </div>
            <div class="input mt-3">
              <label for="salePrice"
                >Цена со скидкой <span>(необязательно)</span></label
              >
              <input
                v-model="formData.sale_price"
                type="number"
                class="form-control mt-2"
                id="salePrice"
                placeholder="Введите цену со скидкой"
                required
              />
            </div>
            <div class="error" role="alert">{{ error }}</div>
            <button class="black-button mt-4" @click.prevent="addProduct">
              Разместить
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import MarketplaceService from "@/services/marketplace";
import CompanyService from "@/services/company";
import productCategoriesService from "@/services/products";

const router = useRouter();
const store = useStore();
const loggedIn = computed(() => store.state.auth.status.loggedIn);
const currentUser = ref({});
const productCategories = ref([]);
const productCompanies = ref([]);
const productTags = ref([]);
const productTagsWithIds = ref([]);
const fileInput = ref(null);
const error = ref(null);
const route = useRoute();

const formData = ref({
  category: "",
  company: "",
});
const formImages = ref([]);
const formTags = ref([]);
const productImages = ref([]);
const newTag = ref("");

onMounted(async () => {
  if (!loggedIn.value) {
    router.push("/");
  } else {
    const user = await store.dispatch("user/me");
    currentUser.value = user;
    // if (!currentUser.value.membership_exists) {
    //   router.push("/plans");
    // }

    CompanyService.getCompany(user.id).then((res) => {
      productCompanies.value = res.result;
    });

    productCategoriesService.all().then((res) => {
      productCategories.value = res;
    });
    if (route.params.id) {
      MarketplaceService.show(route.params.id).then((res) => {
        formData.value.name = res.name;
        formData.value.company = res.company;
        formData.value.description = res.description;
        formData.value.category = res.category.id;
        formData.value.price = parseFloat(
          res.price.toString().replace(",", ".")
        );
        formData.value.sale_price = parseFloat(
          res.sale_price.toString().replace(",", ".")
        );
        // formData.value.sku_article = res.sku_article;
        // formData.value.product_code = res.product_code;
        formTags.value = res.tags;
        productImages.value = res.images;
      });
    }
  }
});

const triggerFileUpload = () => {
  fileInput.value.click();
};
const onFileChange = (event) => {
  if (event.target.files && event.target.files[0]) {
    const images = event.target.files;
    Array.from(images).forEach((item) => {
      productImages.value.push({
        image: URL.createObjectURL(item),
        file: item,
      });
    });
  }
};

const deleteImg = async (index) => {
  await MarketplaceService.deleteImage(productImages.value[index].id);
  productImages.value.splice(index, 1);
  fileInput.value.value = null;
};

const getImagesFromFormData = (formData) => {
  const images = [];
  for (let [key, value] of formData.entries()) {
    if (key === "images") {
      images.push(value);
    }
  }
  return images;
};

const addTag = () => {
  const trimmedTag = newTag.value.trim();
  if (trimmedTag && !formTags.value.includes(trimmedTag)) {
    formTags.value.push(trimmedTag);
    newTag.value = "";
  }
};
// Удаление тега
const removeTag = (index) => {
  formTags.value.splice(index, 1);
};

const addProduct = async () => {
  const entries = Object.entries(formData.value);
  const newProduct = new FormData();

  // Добавляем текстовые данные в FormData
  entries.forEach(([key, value]) => {
    newProduct.append(key, value);
  });

  // Добавляем теги
  for (const item of formTags.value) {
    const data = {
      name: item,
    };
    await productCategoriesService.addTags(data).then((res) => {
      newProduct.append("tags", res.id);
    });
  }

  // Асинхронно обрабатываем изображения
  for (const item of productImages.value) {
    if (item.file) {
      newProduct.append("images", item.file); // Добавляем только новые файлы
    }
  }

  // Проверка всех обязательных полей
  if (
    formData.value.name &&
    formData.value.company &&
    formData.value.description &&
    formData.value.category &&
    formData.value.price &&
    formTags.value &&
    productImages.value.length >= 1
  ) {
    // Проверка цены
    if (
      formData.value.sale_price &&
      formData.value.price < formData.value.sale_price
    ) {
      error.value = "Скидочная цена не может быть выше изначальной";
    } else {
      try {
        if (route.params.id) {
          const imgFormData = new FormData();
          const images = getImagesFromFormData(newProduct);
          images.forEach((image, index) => {
            imgFormData.append(`images`, image);
          });
          await MarketplaceService.update(route.params.id, newProduct);
          if (images.length) {
            await MarketplaceService.addImage(route.params.id, imgFormData);
          }
        } else {
          // Создание нового продукта
          await MarketplaceService.add(newProduct);
        }
        router.push("/profile/products");
      } catch (e) {
        error.value = "Ошибка при сохранении данных";
      }
    }
  } else {
    error.value = "Заполните все поля";
  }
};
</script>

<style scoped lang="scss">
label span {
  color: #a1a1a1;
}
.selectImage {
  background-color: #f7f7f7;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  cursor: pointer;
}
.images {
  &-selected {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    &__block {
      position: relative;

      .close {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        cursor: pointer;
      }
    }
  }
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.remove-tag {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
}

.form-control {
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 150px;
}
</style>
