import { ref } from "vue";
import SupportImg from "../assets/images/icons/support.svg";
import AnalyzeImg from "../assets/images/icons/analyze.svg";
import HistoryImg from "../assets/images/icons/history.svg";
import AddImg from "../assets/images/icons/add.svg";
import SpecialsImg from "../assets/images/icons/specials.svg";
import ChangesImg from "../assets/images/icons/changes.svg";
import SearchImg from "../assets/images/icons/search.svg";
import ConnectionsImg from "../assets/images/icons/connections.svg";
import DealsImg from "../assets/images/icons/deals.svg";

import InfoImg1 from "../assets/images/info-icons/info1.png";
import InfoImg2 from "../assets/images/info-icons/info2.png";
import InfoImg3 from "../assets/images/info-icons/info3.png";
import InfoImg4 from "../assets/images/info-icons/info4.png";
import InfoImg5 from "../assets/images/info-icons/info5.png";
import InfoImg6 from "../assets/images/info-icons/info6.png";
import InfoImg7 from "../assets/images/info-icons/info7.png";
import { Opportunity, Feature } from "./types";

export const opportunities = ref<Opportunity[]>([
  {
    icon: SupportImg,
    title: "Быстрая поддержка",
    description:
      "Это способ общения с клиентами через специальный чат, который интегрирован в веб-страницу сервиса. Этот чат позволяет пользователю задать вопрос, описать проблему, и получить ответ от специалиста поддержки в течение нескольких минут. ",
  },
  {
    icon: AnalyzeImg,
    title: "Экспресс-анализ надежности",
    description:
      "Автоматическая проверка компании по более чем 50 факторам риска. Показывает степень надежности контрагента: низкая, средняя и высокая. Сервис выявляет зоны риска и даёт рекомендации для безопасного сотрудничества. Особенно полезен для быстрой проверки большого количества контрагентов.",
  },
  {
    icon: HistoryImg,
    title: "История изменений",
    description:
      "Перечень ключевых фактов деятельности организации в прошлом. Содержит данные из реестра налоговой, системы госзакупок, картотеки судов, банка исполнительных производств и других баз. Самая востребованная информация — о предыдущих адресах, прежних руководителях и собственниках компании, изменениях уставного капитала и долей в нем.",
  },
  {
    icon: AddImg,
    title: "Добавить контрагента в друзья",
    description:
      "Теперь вы можете добавить контрагента в друзья и следить за всеми событиями.",
  },
  {
    icon: SpecialsImg,
    title: "Специальные реестры",
    description:
      "Отметки о компании в специальных реестрах — показатель повышенных рисков взаимодействия с контрагентом. К ним относятся сведения из реестров дисквалифицированных лиц и недобросовестных поставщиков, а также информация о недостоверности данных. Если такие отметки есть, рекомендуется провести более тщательную проверку.",
  },
  {
    icon: ChangesImg,
    title: "Отслеживание изменений",
    description:
      "Выберите любую организацию, чтобы своевременно узнавать о важных обновлениях у нее. Вы первыми получите сведения о новом руководителе, изменении долей учредителей, смене адреса компании, получении новой лицензии и о других событиях.",
  },
  {
    icon: SearchImg,
    title: "Новый поисковой движок",
    description:
      "Разработан новый поисковый движок, который очень быстро находит необходимую информацию по любому юридическому лицу. Этот поисковый движок использует современные технологии машинного обучения, анализа данных и естественного языка, чтобы обрабатывать и структурировать большие объемы информации из разных источников, таких как реестры, базы данных, судебные решения, СМИ, социальные сети и другие. ",
  },
  {
    icon: ConnectionsImg,
    title: "Связи и аффилированность",
    description:
      "Изучение связей позволит получить более полное представление о деятельности организации. Можно узнать об аффилированности с “однодневками” и выявить сложные цепочки контрагентов. Также это поможет предупредить некоторые налоговые риски. Доступны актуальные сведения и история изменений.",
  },
  {
    icon: DealsImg,
    title: "Арбитражные дела",
    description:
      "Анализ арбитражных споров позволит больше узнать об отношениях компании с ее контрагентами или госорганами. Обратите особое внимание на дела, где организация является ответчиком, а сумма исковых требований велика. Поражение в таких процессах может негативно сказаться на деятельности компании и ее способности выполнять обязательства.",
  },
]);

export const information = ref<any[]>([
  {
    icon: InfoImg1,
    title: "ФНС",
  },
  {
    icon: InfoImg2,
    title: "ФССП",
  },
  {
    icon: InfoImg3,
    title: "Федеральное казначейство",
  },
  {
    icon: InfoImg4,
    title: "ФАС",
  },
  {
    icon: InfoImg5,
    title: "Роспатент",
  },
  {
    icon: InfoImg6,
    title: "Роспотребнадзор",
  },
  {
    icon: InfoImg7,
    title: "Реестр МСП",
  },
]);

export const features = ref<Feature[]>([
  {
    title: "Непрерывный  сбор данных",
    description:
      "Непрерывно собираем актуальную информацию из открытых официальных источников. Показываем все сведения о юридических лицах в структурированном виде",
  },
  {
    title: "Приносим пользу",
    description:
      "Помогаем пользователям выбирать контрагентов и принимать взвешенные решения о сотрудничестве. Сервисом пользуются менеджеры по продажам, руководители и собственники компаний",
  },
  {
    title: "Качество данных",
    description:
      "Надежность, достоверность, актуальность и полнота сведений. Используем специальные технические решения для работы с open data",
  },
  {
    title: "Удобство интерфейса",
    description:
      "Понятный интерфейс и четкая структура помогают анализировать представленные данные и делать выводы быстрее",
  },
  {
    title: "Количество задач",
    description:
      "Количество задач, которые пользователи могут решить с помощью сервиса, постоянно растет",
  },
]);

export const tariffsList = ref<any>({
  Старт: [
    "Ежедневное обновление данных о компаниях",
    "Выявление сложных цепочек связей контрагентов",
    "Проверка сведений о руководителях и учредителях",
    "Оценка финансовых показателей",
    "Данные о физлицах из ЕГРЮЛ, ЕГРИП",
  ],
  Масштабирование: [
    "Возможность делать анонсы и писать статусы компании, они будут попадать в ленту вашим контрагентам",
    "Возможность выпадать в топ 10 по внутреннему поиску, когда потенциальный клиент ищет ваш товар или услугу, вы будете выгодно выделены в отдельном блоке",
    "Возможность первыми получать доступ к новым функциям",
    "Тендеры (все актуальные для вашей отрасли тендеры с удобной сортировкой)",
    "База знаний (курсы и знания для вашей ниши)",
    "Массовая выгрузка товаров и услуг на платформе",
    "Документооборот (заключайте сделки прямо на платформе, доступ к шаблонам документов)",
  ],
  "Оптимальный плюс": [
    "Ежедневное обновление данных о компаниях",
    "Выявление сложных цепочек связей контрагентов",
    "Проверка сведений о руководителях и учредителях",
    "Оценка финансовых показателей",
    "Данные о физлицах из ЕГРЮЛ, ЕГРИП",
    "Доступ к маркетплейсу",
  ],
});
