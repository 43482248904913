<template>
  <div class="card payment">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <h2 class="mb-0">Способы оплаты</h2>
      </div>
      <div class="payment-block text-start">
        <p>Минимальная сумма оплаты — 1 рубль.</p>
        <span>
          К оплате принимаются банковские карты, у которых 16, 18, 19 цифр в
          номере:
          <ul>
            <li>
              VISA, MasterCard, МИР, China UnionPay, JCB и American Express;
            </li>
            <li>
              VISA Electron/Plus, Cirrus/Maestro, если у них есть код CVC2 и
              CVV2;
            </li>
          </ul>
        </span>
        <p>После оплаты заказа вы получите электронный чек на почту.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import moment from "moment";
import MarketplaceService from "@/services/marketplace";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "PaymentInfo",
  setup() {
    const post = ref(null);
    const router = useRouter();
    const route = useRoute();

    const formatDate = (date) => {
      return moment(date).format("hh:mm DD.MM.YYYY");
    };

    onMounted(() => {
      MarketplaceService.getPost(route.params.id).then((res) => {
        post.value = res;
      });
    });
    return { post, formatDate, router };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/PaymentInfo.scss";
</style>
