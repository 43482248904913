<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Лицензии</h5>
      </div>
      <div class="card-body">
        <template v-if="licenses?.length">
          <div
            class="connections-list"
            v-for="(license, index) in licenses"
            :key="index"
          >
            <div class="connections-list__header">
              <p>№ {{ license.number }} от {{ formatDate(license.date) }}</p>
            </div>
            <div class="connections-list__body col-10">
              <p class="value">
                <span class="label">Источник</span> {{ license.license_author }}
              </p>
              <div class="d-flex justify-content-between">
                <p class="value">
                  <span class="label">Дата выдачи</span>
                  {{ formatDate(license.date) }}
                </p>
                <p class="value">
                  <span class="label">Дата начала действия</span>
                  {{ formatDate(license.start_date) || "—" }}
                </p>
                <p class="value">
                  <span class="label">Дата окончания</span>
                  {{ formatDate(license.end_date) || "—" }}
                </p>
              </div>

              <p class="value">
                <span class="label">Вид деятельности</span>
                <template v-if="license.type_activities">{{
                  license.type_activities[0]
                }}</template>
              </p>
            </div>
          </div>
        </template>
        <template v-else><p class="text-center">Нет данных</p> </template>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from "vue";
import moment from "moment";

export default defineComponent({
  name: "Licenses",
  props: {
    licenses: Array,
  },
  setup() {
    const selectedType = ref("Все");
    const formatDate = (date) => {
      return moment(date).format("DD.MM.YYYY");
    };
    return {
      selectedType,
      formatDate,
    };
  },
});
</script>
