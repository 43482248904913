<template>
  <div class="profile">
    <div class="card profile-info">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <h2>Основные данные</h2>
          <button class="edit-button" @click="editable = !editable">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.6404 9.69608L10.0778 5.25864C9.33125 4.94694 8.65323 4.49152 8.08231 3.91829C7.50881 3.34724 7.05318 2.669 6.74136 1.92216L2.30392 6.3596C1.95773 6.70579 1.78433 6.87919 1.63554 7.06998C1.45981 7.29514 1.30913 7.53876 1.18615 7.79655C1.08236 8.01495 1.00496 8.24774 0.850167 8.71212L0.0329961 11.1618C-0.00461429 11.274 -0.0101944 11.3944 0.016883 11.5096C0.0439603 11.6247 0.102622 11.7301 0.186274 11.8137C0.269925 11.8974 0.375251 11.956 0.490412 11.9831C0.605573 12.0102 0.726004 12.0046 0.838167 11.967L3.28788 11.1498C3.75286 10.995 3.98505 10.9176 4.20345 10.8138C4.46144 10.6909 4.70503 10.5403 4.93002 10.3645C5.12081 10.2157 5.29421 10.0423 5.6404 9.69608ZM11.309 4.02749C11.7514 3.58504 12 2.98496 12 2.35925C12 1.73354 11.7514 1.13345 11.309 0.691008C10.8665 0.248563 10.2665 4.66191e-09 9.64075 0C9.01504 -4.66191e-09 8.41496 0.248563 7.97251 0.691008L7.44033 1.22319L7.46313 1.28979C7.72539 2.04019 8.15455 2.72128 8.71829 3.28171C9.29548 3.86231 10.0004 4.29994 10.7768 4.55967L11.309 4.02749Z"
                fill="#3E3E3E"
              />
            </svg>
          </button>
        </div>

        <form @submit.prevent="submitForm" class="mt-4">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="fullName">ФИО:</label>
                <input
                  type="text"
                  id="fullName"
                  class="border-bottom-only mt-2"
                  v-model="form.fullName"
                  :disabled="!editable"
                />
              </div>
              <div class="form-group mt-2">
                <label for="birthDate">Дата рождения:</label>
                <input
                  type="date"
                  id="birthDate"
                  class="border-bottom-only mt-2"
                  v-model="form.birthDate"
                  :disabled="!editable"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="phone">Телефон:</label>
                <input
                  type="tel"
                  id="phone"
                  class="border-bottom-only mt-2"
                  v-model="form.phone"
                  :disabled="!editable"
                />
              </div>
              <div class="form-group mt-2">
                <label for="email">Почта:</label>
                <input
                  type="email"
                  class="border-bottom-only mt-2"
                  v-model="form.email"
                  :disabled="!editable"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="circle" @click="triggerFileUpload">
                <img
                  v-if="currentUser.avatar"
                  :src="currentUser.avatar"
                  alt="User Avatar"
                />
                <img
                  v-else
                  src="@/assets/images/user-icon.png"
                  alt="User Avatar"
                />
                <button class="img-button" @click="triggerFileUpload">
                  <svg
                    width="15"
                    height="13"
                    viewBox="0 0 15 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.2692 1.69565H11.2702L10.2873 0.251522C10.2346 0.174194 10.1633 0.110785 10.0796 0.0669115C9.99598 0.0230384 9.90258 5.58187e-05 9.80769 0H5.19231C5.09742 5.58187e-05 5.00402 0.0230384 4.92037 0.0669115C4.83672 0.110785 4.7654 0.174194 4.71274 0.251522L3.72909 1.69565H1.73077C1.27174 1.69565 0.831513 1.8743 0.506931 2.1923C0.182348 2.51029 0 2.94159 0 3.3913V11.3043C0 11.7541 0.182348 12.1854 0.506931 12.5034C0.831513 12.8214 1.27174 13 1.73077 13H13.2692C13.7283 13 14.1685 12.8214 14.4931 12.5034C14.8177 12.1854 15 11.7541 15 11.3043V3.3913C15 2.94159 14.8177 2.51029 14.4931 2.1923C14.1685 1.8743 13.7283 1.69565 13.2692 1.69565ZM10.0962 7.06522C10.0962 7.56827 9.94389 8.06003 9.65862 8.4783C9.37335 8.89657 8.96789 9.22257 8.4935 9.41508C8.01912 9.60759 7.49712 9.65796 6.99352 9.55982C6.48991 9.46168 6.02732 9.21944 5.66424 8.86373C5.30116 8.50802 5.0539 8.05481 4.95373 7.56143C4.85356 7.06804 4.90497 6.55663 5.10147 6.09187C5.29796 5.62711 5.63072 5.22987 6.05765 4.95039C6.48459 4.67091 6.98653 4.52174 7.5 4.52174C8.18854 4.52174 8.84888 4.78971 9.33576 5.26671C9.82263 5.7437 10.0962 6.39065 10.0962 7.06522Z"
                      fill="#3E3E3E"
                    />
                  </svg>
                </button>
                <input
                  type="file"
                  ref="fileInput"
                  @change="uploadImage"
                  accept="image/*"
                  class="d-none"
                />
              </div>
            </div>
          </div>
          <button type="submit" class="black-button mt-4" v-if="editable">
            Сохранить
          </button>
        </form>
      </div>
    </div>
    <div class="card mt-4 profile-security">
      <div class="card-body">
        <h2>Безопасность</h2>
        <ul class="info-list row mt-4">
          <li class="col-md-4 col-12">
            <span style="color: #a3a3a3">Номер телефона</span>
            <span class="info-value">{{ currentUser.phone_number }}</span>
          </li>
          <li class="col-md-4 col-12">
            <span style="color: #a3a3a3">E-mail</span>
            <span class="info-value">{{ currentUser.email }}</span>
          </li>
          <li class="col-md-4 col-12">
            <span style="color: #a3a3a3">Дата регистрации</span>
            <span class="info-value">{{
              formatDate(currentUser.date_joined)
            }}</span>
          </li>
        </ul>
        <button
          class="btn btn-link mt-4"
          data-bs-toggle="modal"
          data-bs-target="#passwordModal"
        >
          Сменить пароль
        </button>
        <PasswordModal />
      </div>
    </div>
    <div class="card mt-4 profile-security">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
          <h2>Компания</h2>
          <button
            class="black-button"
            @click="router.push('/profile/add-company')"
          >
            Привязать к компании
          </button>
        </div>
        <ul class="info-list row mt-4">
          <li class="col-md-4 col-12">
            <span style="color: #a3a3a3">Название</span>
            <span class="info-value">{{ fullCompany.name_full }}</span>
          </li>
          <li class="col-md-4 col-12">
            <span style="color: #a3a3a3">ИНН</span>
            <span class="info-value">{{ fullCompany.inn }}</span>
          </li>
          <li class="col-md-4 col-12">
            <span style="color: #a3a3a3">Адрес</span>
            <span class="info-value">{{
              fullCompany.legal_adress?.adress
            }}</span>
          </li>
        </ul>
        <ul class="info-list row mt-4">
          <li class="col-md-4 col-12">
            <span style="color: #a3a3a3">ОГРН</span>
            <span class="info-value">{{ fullCompany.ogrn }}</span>
          </li>
          <li class="col-md-4 col-12">
            <span style="color: #a3a3a3">Дата регистрации</span>
            <span class="info-value">{{
              formatDate(fullCompany.registration_date)
            }}</span>
          </li>
          <li class="col-md-4 col-12">
            <span style="color: #a3a3a3">Статус</span>
            <span class="info-value">{{ fullCompany.status?.name }}</span>
          </li>
        </ul>
      </div>
    </div>
    <CropModal :newAvatar="newAvatar" />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import UserService from "@/services/user";
import PasswordModal from "@/components/Modals/PasswordModal.vue";
import CompanyService from "@/services/company";
import { Modal } from "bootstrap";
import CropModal from "@/components/Modals/CropModal.vue";
import moment from "moment";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ProfileInfo",
  components: { PasswordModal, CropModal },
  setup() {
    const store = useStore();
    const currentUser = computed(() => store.state.user.user);
    const fileInput = ref(null);
    const newAvatar = ref(null);
    const editable = ref(false);
    const isOpen = ref(false);
    const userCompany = ref({});
    const fullCompany = ref({});
    const imageElement = ref(null);
    const router = useRouter();

    const form = ref({
      fullName: currentUser.value.full_name,
      birthDate: currentUser.value.birth_date,
      position: currentUser.value.position,
      phone: currentUser.value.phone_number,
      email: currentUser.value.email,
    });

    const formatDate = (date) => {
      return moment(date).format("DD.MM.YYYY");
    };

    const openModal = () => {
      const cropModal = new Modal(document.getElementById("cropModal"));
      cropModal.show();
    };

    // Триггер для загрузки изображения
    const triggerFileUpload = () => {
      fileInput.value.click();
    };

    const uploadImage = (event) => {
      const file = event.target.files[0];
      if (file) {
        newAvatar.value = URL.createObjectURL(file);
        openModal();
      }
    };

    const submitForm = async () => {
      const data = {
        full_name: form.value.fullName,
        birth_date: form.value.birthDate,
        email: form.value.email,
        phone_number: form.value.phone,
      };

      UserService.patch(data);
      editable.value = false;
    };

    const openPasswordModal = () => {
      isOpen.value = true;
    };
    onMounted(async () => {
      await CompanyService.getCompany(currentUser.value.id).then((res) => {
        userCompany.value = res.result[0];
      });
      if (userCompany.value) {
        fullCompany.value = await store.dispatch(
          "companies/getById",
          userCompany.value.id
        );
      }
    });
    return {
      currentUser,
      form,
      editable,
      isOpen,
      fileInput,
      newAvatar,
      userCompany,
      imageElement,
      fullCompany,
      router,
      submitForm,
      triggerFileUpload,
      uploadImage,
      openPasswordModal,
      formatDate,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/Profile.scss";
</style>
