<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">История</h5>
      </div>
      <div class="card-body">
        <p>
          В разделе содержатся существенные факты деятельности организации в
          прошлом. Наибольший интерес представляет информация о предыдущих
          руководителях и адресах, изменении структуры учредителей и размера их
          долей.
        </p>

        <div class="filter-container d-flex align-items-center my-4">
          <label for="type-filter">Период</label>
          <select
            id="type-filter"
            class="form-select w-auto ms-4"
            v-model="selectedType"
            @change="filterOrganizations"
          >
            <option value="Все">Все</option>
            <option value="Действующая">Действующая</option>
            <option value="Ликвидирована">Ликвидирована</option>
            <option value="Не действующая">Не действующая</option>
          </select>
        </div>
      </div>
      <div
        class="connections-list"
        v-for="(org, index) in filteredOrganizations"
        :key="index"
        style="filter: blur(5px)"
      >
        <div class="connections-list__header">
          <h4>Актуальные</h4>
          <!-- <span class="status liquidated">{{ org.status }}</span> -->
        </div>
        <div
          class="connections-list__body d-flex justify-content-between col-10 flex-md-row flex-column"
        >
          <p class="value">Лобанова Елизавета Сергеевна</p>
          <span class="label">Дата</span>
          <p class="value">21.09.2018</p>
          <span class="label">Доля</span>
          <p class="value">4647338833 р.</p>
          <span class="label">ИНН</span>
          <p class="value">4647338833</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from "vue";

export default defineComponent({
  name: "History",
  setup() {
    const selectedType = ref("Все");
    const organizations = ref([
      {
        name: 'АО "Тандер"',
        status: "Ликвидирована",
        statusClass: "active",
        director: "Лобанова Елизавета Сергеевна",
        address:
          "142520, Московская область, г. Павловский Посад, ул. Белозерская, д. 11А",
        inn: "4547487477",
        ogrn: "4547487477",
        registrationDate: "15.01.1999",
        capital: "100 000 000 р.",
        activity:
          "47.11 Торговля розничная преимущественно пищевыми продуктами",
      },
      {
        name: 'АО "Тандер"',
        status: "Действующая",
        statusClass: "active",
        director: "Лобанова Елизавета Сергеевна",
        address:
          "142520, Московская область, г. Павловский Посад, ул. Белозерская, д. 11А",
        inn: "4547487477",
        ogrn: "4547487477",
        registrationDate: "15.01.1999",
        capital: "100 000 000 р.",
        activity:
          "47.11 Торговля розничная преимущественно пищевыми продуктами",
      },
    ]);
    const filteredOrganizations = computed(() => {
      if (selectedType.value === "Все") {
        return organizations.value;
      }
      return organizations.value.filter(
        (org) => org.status === selectedType.value
      );
    });
    return {
      selectedType,
      filteredOrganizations,
    };
  },
});
</script>
