<template>
  <section class="pricing-section mb-md-0 mb-4" id="pricing-section">
    <div class="container">
      <h2 class="text-center">Инструмент для вашего бизнеса</h2>
      <template v-if="tariffs['yearly'].length && tariffs['monthly'].length">
        <div class="toggle">
          <button
            class="mx-2"
            :class="{ active: type === 'monthly' }"
            @click="setType('monthly')"
          >
            Ежемесячно
          </button>
          /
          <button
            class="mx-2"
            :class="{ active: type === 'yearly' }"
            @click="setType('yearly')"
          >
            Ежегодно
          </button>
        </div>
      </template>
      <div class="pricing-grid row justify-content-between">
        <div class="col-md-5 pricing-text">
          <p>
            Infoglobal открывает возможности для автоматизации и масштабирования
            бизнеса, обеспечивая:
          </p>
          <ul>
            <li>
              Увеличение продаж благодаря широкому доступу к новым клиентам и
              партнёрам.
            </li>
            <li>
              Автоматизация коммуникаций с перспективой внедрения ИИ ботов,
              которые будут взаимодействовать с компаниями напрямую, экономя
              время и ресурсы.
            </li>
            <li>
              Оптимизация бизнес-процессов: сокращение необходимости вручную
              искать клиентов позволит вашим менеджерам сосредоточиться на
              других стратегически важных задачах.
            </li>
            <li>
              Поддержка прозрачности и открытости в деловых отношениях, что
              укрепляет доверие среди партнёров и клиентов. С таким подходом
              можно значительно повысить эффективность работы и ускорить рост
              бизнеса. Это действительно может стать мощным шагом к большему
              успеху!
            </li>
          </ul>
        </div>
        <div
          class="pricing-item mr-2 d-flex flex-column justify-content-between w-xs-100 mt-md-0 mt-2"
          v-for="(tariff, index) in tariffs[type]"
          :key="index"
        >
          <template v-if="!isLoading">
            <div class="pricing-item-content">
              <h4 class="mb-3">{{ tariff.name }}</h4>
              <h3 class="mb-3">
                {{ tariff.price }}₽
                <!-- <span class="line-through">{{ tariff.discount }}₽</span> -->
              </h3>
              <div class="divider"></div>
              <ul v-html="tariff.description" class="mt-4"></ul>
            </div>
            <button @click.prevent="goToPayment(tariff.id)">
              <template v-if="!isPaymentLoading"> Подключиться </template>
              <div v-else class="loader my-auto"></div>
            </button>
          </template>
          <div v-else class="loader my-auto"></div>
        </div>
      </div>
    </div>
    <!-- <TariffModal :selectedPlans="selectedPlans" /> -->
  </section>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from "vue";
import { tariffsList } from "@/models/main-page";
import { useStore } from "vuex";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "Pricing",
  setup() {
    const isLoading = ref(true);
    const isPaymentLoading = ref(false);
    const store = useStore();
    const loggedIn = computed(() => store.state.auth.status.loggedIn);
    let type = ref("monthly");
    const plans = ref([]);
    const selectedPlans = ref([]);
    const tariffs = ref({
      monthly: [],
      yearly: [],
    });
    const memberships = ref([]);

    const setType = (value) => {
      type.value = value;
    };

    const getPlans = () => {
      plans.value.forEach((el) => {
        if (el.type === "monthly") {
          tariffs.value["monthly"].push(el);
        }
        if (el.type === "yearly") {
          tariffs.value["yearly"].push(el);
        }
        isLoading.value = false;
      });
    };

    const selectPlan = (name) => {
      selectedPlans.value = [];
      plans.value.forEach((el) => {
        if (el.name.split(" ")[0] === name.split(" ")[0]) {
          selectedPlans.value.push(el);
        }
      });
    };

    const goToPayment = (plan) => {
      isPaymentLoading.value = true;
      if (loggedIn.value) {
        store.dispatch("payments/createOrder", plan).then((res) => {
          window.location.href = res.order_url;
          isPaymentLoading.value = false;
        });
      } else {
        isPaymentLoading.value = false;
        const loginModal = new Modal(document.getElementById("authModal"));
        loginModal.show();
      }
    };

    onMounted(() => {
      if (loggedIn.value) {
        store.dispatch("payments/getMemberships").then((res) => {
          memberships.value = res;
        });
      }

      // Получение списка тарифов
      store.dispatch("payments/getPlans").then((res) => {
        plans.value = res;
        getPlans();
      });
    });
    return {
      type,
      tariffs,
      plans,
      tariffsList,
      selectedPlans,
      isLoading,
      isPaymentLoading,
      setType,
      selectPlan,
      goToPayment,
    };
  },
});
</script>
