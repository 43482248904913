import { ActionContext, Module } from "vuex";

interface ChatsState {
  selectId: number | null;
  selectSlug: string;
}

export const chats: Module<ChatsState, any> = {
  namespaced: true,

  state: (): ChatsState => ({
    selectId: null,
    selectSlug: "",
  }),

  actions: {
    SelectChat(
      { commit }: ActionContext<ChatsState, any>,
      value: {
        selectId: number | null;
        selectSlug: string;
      }
    ) {
      commit("setSelectChat", value);
    },
  },

  mutations: {
    setSelectChat(state, value) {
      state.selectId = value.selectId;
      state.selectSlug = value.selectSlug;
    },
  },
};
