<template>
  <div class="col-12 connections py-4 mb-4">
    <template v-if="cases?.length">
      <div class="d-flex flex-column flex-md-row">
        <div class="filter-container d-flex align-items-center my-4">
          <label for="type-filter">Поиск</label>
          <input
            type="text"
            class="form-control ms-4"
            placeholder="Номер дела"
            v-model="searchQuery"
          />
        </div>
        <div
          class="filter-container d-flex flex-column flex-md-row align-items-center my-4"
        >
          <label for="role-filter">Период</label>
          <input
            type="date"
            id="start-date"
            class="form-control ms-4"
            v-model="startDate"
          />
          <input
            type="date"
            id="end-date"
            class="form-control ms-4"
            v-model="endDate"
          />
        </div>
      </div>
      <div class="connections-list-container">
        <div
          class="connections-list"
          v-for="item in filteredCases"
          :key="item.id"
        >
          <div class="connections-list__header">
            <h4>Дело {{ item.number }}</h4>
          </div>
          <div class="connections-list__body">
            <p class="director">
              <span>Дата регистрации </span>{{ formatDate(item.date) }}
            </p>
            <p class="details">
              <span>Истец: {{ item.plaints[0]?.address }}</span> |
              <span>Ответчик: {{ item.responders[0]?.address }}</span> |
              <span>Иное лицо: {{ item.court }}</span> |
              <span>Сумма: {{ item.amount_claim }}</span>
            </p>
            <a class="btn-link" :href="item.link_on_card" target="_blank"
              >О принятии исходного дела</a
            >
          </div>
        </div>
      </div>
    </template>
    <template v-else><p class="text-center">Нет данных</p></template>
  </div>
</template>
<script>
import { defineComponent, ref, computed, watch } from "vue";
import moment from "moment";

export default defineComponent({
  name: "ArbitrationDeals",
  props: {
    cases: Array,
  },
  setup(props) {
    const selectedType = ref("Все");
    const searchQuery = ref("");
    const filteredCases = ref([]);
    const startDate = ref("");
    const endDate = ref("");

    const formatDate = (date) => {
      return moment(date).format("DD.MM.YYYY");
    };

    watch(
      () => searchQuery.value,
      (newValue, oldValue) => {
        filterCases();
      }
    );
    const filterCases = () => {
      filteredCases.value = props.cases?.filter((item) => {
        // Фильтрация по поисковому запросу
        const matchesSearch = searchQuery.value
          ? item.number
              .toString()
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase())
          : true;
        const startMatch =
          !startDate.value ||
          new Date(item.start_date) >= new Date(startDate.value);
        const endMatch =
          !endDate.value || new Date(item.end_date) <= new Date(endDate.value);

        return startMatch && endMatch && matchesSearch;
      });
    };
    filterCases();
    return {
      selectedType,
      filteredCases,
      searchQuery,
      startDate,
      endDate,
      formatDate,
      filterCases,
    };
  },
});
</script>
