import api from "./api";

class NewsService {
  all() {
    return api.get("/api/v1/news").then((res) => {
      return res.data;
    });
  }
  show(id: string) {
    return api.get(`/api/v1/news/${id}/`).then((res) => {
      return res.data;
    });
  }
  getSubscribers() {
    return api.get(`/api/v1/counter`).then((res) => {
      return res.data;
    });
  }
}

export default new NewsService();
