
import { defineComponent, computed, watchEffect, ref, watch } from "vue";
import SearchComponent from "./SearchComponent.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Burger from "./Burger.vue";
import AuthModal from "../components/Modals/AuthModal.vue";
import Translator from "./Translator.vue";

export default defineComponent({
  components: { SearchComponent, Burger, AuthModal, Translator },
  name: "SearchHeader",
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.state.auth.status.loggedIn);
    const currentUser = computed(() => store.state.user.user);
    const route = useRoute();
    const router = useRouter();

    watchEffect(() => {
      if (isLoggedIn.value && !currentUser.value) {
        store.dispatch("user/me");
      }
    });
    return {
      currentUser,
      isLoggedIn,
      route,
      router,
    };
  },
});
