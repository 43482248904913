<template>
  <div class="card blog">
    <div class="card-body" v-if="news?.length">
      <h2>Новости и статьи</h2>
      <div
        class="blog-block d-md-flex text-start mt-4"
        v-for="post in news"
        :key="post.id"
      >
        <img
          :src="post.image || require('@/assets/images/logo_info.svg')"
          class="blog-block__image"
        />
        <div class="ms-md-4 col">
          <h3>
            <router-link :to="`/blog/${post.id}`">{{ post.title }}</router-link>
          </h3>
          <p class="content" v-html="truncateContent(post.content)"></p>
          <router-link :to="`/blog/${post.id}`" class="btn-link"
            >Читать полностью</router-link
          >
          <p class="date mt-4">
            {{ formatDate(post.created_at) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import NewsService from "@/services/news";
import moment from "moment";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Blog",
  setup() {
    const news = ref([]);
    const router = useRouter();

    const formatDate = (date) => {
      return moment(date).format("hh:mm DD.MM.YYYY");
    };

    const truncateContent = (content) => {
      const limit = 120;
      return content.length > limit ? content.slice(0, limit) + "..." : content;
    };

    onMounted(() => {
      NewsService.all().then((res) => {
        news.value = res.result.reverse();
      });
    });
    return { news, router, formatDate, truncateContent };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/Blog.scss";
</style>
