<template>
  <div class="profile friends">
    <div class="card profile-info full-height">
      <div class="card-body">
        <h2>Друзья</h2>
        <div class="friends-list mt-4" v-if="friends?.length">
          <div
            class="friends-list__item d-flex"
            v-for="item in friends"
            :key="item.friend.id"
          >
            <img :src="item.friend.avatar" class="avatar" />
            <div class="ms-4">
              <h4>{{ item.friend.full_name }}</h4>
              <span>{{ item.friend.email }}</span>

              <button
                class="border-button blue ms-md-auto px-4 d-block d-md-none"
                @click.prevent="openChat(item.friend.id)"
              >
                Написать
              </button>
            </div>
            <button
              class="border-button blue ms-md-auto px-4 d-md-block d-none"
              @click.prevent="openChat(item.friend.id)"
            >
              Написать
            </button>
            <!-- <button
              class="border-button red ms-auto px-4"
              @click.prevent="removeFriend(item.friend.id)"
            >
              Удалить
            </button> -->
          </div>
        </div>
        <p class="mt-4" v-else>Список друзей пуст</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import CompanyService from "@/services/company";
import ChatRoomService from "@/services/chatRoom";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Friends",
  setup() {
    const friends = ref([]);
    const isCollapsed = ref(true);
    const router = useRouter();
    const store = useStore();

    function toggleCollapse() {
      isCollapsed.value = !isCollapsed.value;
    }

    const removeFriend = async (id) => {
      await CompanyService.removeFriend(id);
    };

    const getFriends = () => {
      CompanyService.getFriends().then((res) => {
        friends.value = res;
      });
    };

    const openChat = async (id) => {
      const friendsChat = await ChatRoomService.getFriendsChats(id);
      await store.dispatch("chats/SelectChat", {
        selectSlug: friendsChat.slug,
      });

      router.push("/profile/messages");
    };

    onMounted(() => {
      getFriends();
    });
    return { friends, isCollapsed, toggleCollapse, removeFriend, openChat };
  },
});
</script>
