<template>
  <div class="card main-info">
    <div class="card-body">
      <h5 class="card-title">Финансы</h5>
      <ul class="info-list d-none">
        <div class="d-flex pt-3">
          <li class="col-4">
            <span class="info-title">Выручка</span>
            <span class="info-bold">390 млр. р.</span>
          </li>
          <li class="col-4">
            <span class="info-value">Себестоимость</span>
            <span class="info-bold">390 млр. р.</span>
          </li>
          <li class="col-4">
            <span class="info-value">Прибыль</span>
            <span class="info-bold">390 млр. р.</span>
          </li>
        </div>
      </ul>
      <LineChart class="mt-2" :finances="finances" />
      <ul class="info-list mt-3">
        <li><span class="info-title">Финансовое состояние</span></li>
        <li
          v-for="(factor, index) in factors"
          :key="index"
          class="flex flex-row justify-content-between align-items-center border-bottom mt-2"
        >
          <span class="info-value success w-auto">{{ factor.title }}</span
          ><span class="info-bold">{{ factor.text }}</span>
        </li>
      </ul>
      <button
        class="btn-primary btn-small d-block ms-auto mt-2"
        @click="emitChangeTab('tab-finances')"
      >
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
import LineChart from "./Diagrams/LineChart.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "FinancesInfo",
  components: { LineChart },
  props: {
    finances: Object,
    html: String,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props, { emit }) {
    const factors = ref([]);

    const emitChangeTab = (newTab) => {
      emit("change-tab", newTab);
    };
    const getParsing = () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(props.html, "text/html");
      const finances = doc.getElementById("finances");
      const info = finances?.querySelector("#accounting-huge");
      const table = finances?.querySelector("table");
      if (info) {
        const rows = info?.querySelectorAll(".col-12");
        if (rows?.length) {
          rows?.forEach((stroke, index) => {
            const rowTitle = stroke.querySelector("em");
            const rowValue = stroke.querySelector("a");
            if (rowTitle?.textContent && rowValue?.textContent !== "Активы") {
              const tableItemObject = {
                id: index + 1,
                title: rowTitle.textContent,
                text: rowValue.textContent,
              };
              factors.value.push(tableItemObject);
            }
          });
        }
      } else {
        if (table) {
          const tr = table?.querySelectorAll("tr");
          if (tr?.length) {
            tr?.forEach((stroke, index) => {
              const tdFirst = stroke.querySelector("td:nth-child(1)");
              const tdSecond = stroke.querySelector("td:nth-child(2) span");
              if (tdFirst?.textContent && tdFirst?.textContent !== "Активы") {
                const tableItemObject = {
                  id: index + 1,
                  title: tdFirst.textContent,
                  text: tdSecond.textContent,
                };
                factors.value.push(tableItemObject);
              }
            });
          }
        }
      }
    };
    if (props.html) {
      getParsing();
    }
    return {
      emitChangeTab,
      factors,
    };
  },
});
</script>
