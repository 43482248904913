<template>
  <div
    class="modal fade auth-modal"
    id="newPasswordModal"
    tabindex="-1"
    aria-labelledby="newPasswordModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Сменить пароль</h2>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="setPassword" class="mt-4" v-if="!text">
            <div class="mb-3">
              <label for="currentPassword" class="form-label"
                >Новый пароль</label
              >
              <input
                type="password"
                class="form-control"
                id="currentPassword"
                v-model="user.new_password"
                autocomplete="new-password"
                required
              />
            </div>
            <div class="mb-3">
              <label for="newPassword" class="form-label"
                >Подтвердите новый пароль</label
              >
              <input
                type="password"
                class="form-control"
                id="newPassword"
                v-model="confirmPassword"
                autocomplete="new-password"
                required
              />
              <div
                class="error"
                role="alert"
                v-if="confirmPassword && !isPasswordMatching"
              >
                Пароли не совпадают
              </div>
            </div>
            <button type="submit" class="black-button mt-4">Сохранить</button>
          </form>
          <p v-else>{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from "vue";
import AuthService from "../../services/auth";
import { Modal } from "bootstrap";
import { useRoute } from "vue-router";

export default defineComponent({
  nmae: "NewPasswordModal",
  setup() {
    const route = useRoute();
    const user = ref({
      uid: route.query.uid,
      token: route.query.token,
      new_password: "",
    });
    const confirmPassword = ref("");
    const text = ref(null);
    let modalInstance = null;

    const isPasswordMatching = computed(() => {
      return user.value.new_password === confirmPassword.value;
    });

    const setPassword = async () => {
      AuthService.resetPasswordConfirm(user.value)
        .then((response) => {
          text.value = "Ваш пароль успешно обновлен";
          return Promise.resolve(response);
        })
        .catch((error) => {
          alert("Не удалось обновить пароль");
          return Promise.reject(error);
        });
    };
    const openModal = () => {
      if (modalInstance) {
        modalInstance.show();
      }
    };

    onMounted(() => {
      const modalElement = document.getElementById("newPasswordModal");
      if (modalElement) {
        modalInstance = new Modal(modalElement);
      } else {
        console.error("Модальное окно не найдено");
      }
    });
    return {
      user,
      text,
      isPasswordMatching,
      confirmPassword,
      setPassword,
      openModal,
    };
  },
});
</script>
