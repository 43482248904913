<template>
  <div
    class="modal fade buy-modal"
    id="buyModal"
    tabindex="-1"
    aria-labelledby="buyModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img src="@/assets/images/logo.svg" alt="Info Global Logo" />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          ></button>
        </div>
        <div class="modal-body px-4">
          <!-- <h3 class="title">Заказ на сумму: ₽</h3> -->
          <form class="form">
            <label class="mt-3" for="address">Адрес</label>
            <input
              class="form-control mt-2"
              v-model="form.address"
              id="address"
              placeholder="Введите адрес"
            />
            <label class="mt-3" for="postal_code">Почтовый индекс</label>
            <input
              class="form-control mt-2"
              v-model="form.postal_code"
              id="postal_code"
              placeholder="Почтовый индекс адреса"
            />
            <label class="mt-3" for="phone_number">Телефон для связи</label>
            <input
              class="form-control mt-2"
              type="phone"
              id="phone_number"
              v-model="form.phone_number"
              placeholder="Номер телефона"
            />
            <button
              class="btn btn-primary mt-2 mx-auto d-block"
              @click.prevent="goToBuy"
            >
              Перейти к оплате
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent } from "vue";
import marketplaceService from "@/services/marketplace";

export default defineComponent({
  name: "BuyModal",
  components: {},
  setup() {
    const form = ref({
      address: "",
      postal_code: "",
      phone_number: "",
    });

    const goToBuy = () => {
      marketplaceService.getBuyLink(form.value).then((res) => {
        console.log("result", res);
        window.location.replace(res.order_url);
      });
    };
    return {
      form,
      goToBuy,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/auth.scss";
</style>
