
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import ProductCard from "@/components/Marketplace/ProductCard.vue";
import MarketplaceService from "@/services/marketplace";
import { useRoute } from "vue-router";
import type { Product, Category } from "@/services/marketplace";
import { useStore } from "vuex";
import productCategoriesService from "@/services/products";

export default defineComponent({
  name: "MarketplacePage",
  components: {
    ProductCard,
  },
  setup() {
    const selectedSort = ref("popularity");
    const selectedOrgTypes = ref<string[]>([]);
    const selectedRegion = ref("");
    const minPrice = ref(null);
    const maxPrice = ref(null);
    const appliedFilters = ref<string[]>([]);
    const searchQuery = ref("");

    const products = ref<Product[]>([]);
    const route = useRoute();
    const store = useStore();
    const categories = ref<Category[]>([]);
    const selectedCategories = ref<string[]>([]);
    const isOpen = ref(false);

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };

    const getCategoryName = (categoryId: string) => {
      const category = categories.value.find((cat) => cat.id === categoryId);
      return category ? category.name : "Неизвестная категория";
    };

    const getCategories = () => {
      productCategoriesService.all().then((res) => {
        categories.value = res;
      });
    };

    // Логика фильтрации
    const filteredProducts = computed(() => {
      let filtered = products.value;

      // Фильтрация по стоимости
      if (minPrice.value !== null) {
        filtered = filtered.filter(
          (product) => product.price >= (minPrice.value || 0)
        );
      }
      if (maxPrice.value !== null) {
        filtered = filtered.filter(
          (product) => product.price <= (maxPrice.value || 0)
        );
      }

      if (selectedCategories.value.length) {
        filtered = filtered.filter((product) =>
          selectedCategories.value.includes(product.category.id)
        );
      }
      // Сортировка
      if (selectedSort.value === "price-asc") {
        filtered.sort((a, b) => a.price - b.price);
      } else if (selectedSort.value === "price-desc") {
        filtered.sort((a, b) => b.price - a.price);
      } else if (selectedSort.value === "popularity") {
        filtered.sort((a, b) => b.rating - a.rating);
      }
      return filtered;
    });

    const getProducts = () => {
      const page = parseInt(route.query.page as string, 10) || 1;
      MarketplaceService.all(page).then((res) => {
        products.value = res;
      });
    };

    const updateFilters = () => {
      appliedFilters.value = [];

      // Добавление выбранных форм организации
      if (selectedOrgTypes.value.length > 0) {
        appliedFilters.value.push(...selectedOrgTypes.value);
      }

      // Добавление диапазона цен
      if (minPrice.value !== null) {
        appliedFilters.value.push(`От ${minPrice.value}₽`);
      }
      if (maxPrice.value !== null) {
        appliedFilters.value.push(`До ${maxPrice.value}₽`);
      }
    };

    const removeFilter = (filter: string) => {
      if (selectedOrgTypes.value.includes(filter)) {
        selectedOrgTypes.value = selectedOrgTypes.value.filter(
          (f) => f !== filter
        );
      } else if (selectedCategories.value.includes(filter)) {
        selectedCategories.value = selectedCategories.value.filter(
          (category) => category !== filter
        );
      } else if (filter === selectedRegion.value) {
        selectedRegion.value = "";
      } else if (filter.startsWith("От")) {
        minPrice.value = null;
      } else if (filter.startsWith("До")) {
        maxPrice.value = null;
      }

      updateFilters();
    };
    const onSearchByRegions = () => {
      if (searchQuery.value) {
        store.dispatch("companies/searchProducts", searchQuery.value);
      }
    };
    const preventNegativeInput = (event: any) => {
      if (event.key === "-" || event.key === "e") {
        event.preventDefault();
      }
    };
    onMounted(() => {
      getProducts();
      getCategories();
    });

    watch(
      [selectedOrgTypes, selectedRegion, minPrice, maxPrice],
      updateFilters,
      {
        immediate: true,
      }
    );

    return {
      searchQuery,
      appliedFilters,
      selectedSort,
      products,
      filteredProducts,
      minPrice,
      maxPrice,
      selectedCategories,
      categories,
      isOpen,
      toggleDropdown,
      removeFilter,
      getProducts,
      onSearchByRegions,
      preventNegativeInput,
      getCategoryName,
    };
  },
});
