<template>
  <div>
    <h5 class="modal-title">Восстановить пароль</h5>
    <template v-if="!message">
      <p>
        Письмо с дальнейшими инструкциями будет отправлено вам на указанный
        почтовый адрес.
      </p>
      <form @submit.prevent="resetPassword">
        <div class="mb-3">
          <label for="email" class="form-label">E-mail</label>
          <input
            type="email"
            class="form-control"
            id="reset-email"
            v-model="email"
            required
          />
        </div>
        <button type="submit" class="btn btn-primary">Продолжить</button>
        <button
          type="button"
          class="btn btn-link"
          @click="switchView('AuthView')"
        >
          Вспомнили пароль?
        </button>
      </form>
    </template>
    <p v-else>{{ message }}</p>
  </div>
</template>

<script>
import { ref } from "vue";
import AuthService from "../services/auth";

export default {
  name: "ForgotPasswordView",
  setup(props, { emit }) {
    const email = ref("");
    const message = ref(null);

    const resetPassword = () => {
      const data = {
        email: email.value,
      };

      AuthService.resetPassword(data)
        .then((response) => {
          message.value =
            "Письмо со ссылкой на восстановление аккаунта отправлено Вам на почту";
          return Promise.resolve(response);
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response.data.email[0] ===
            "Пользователь with this Электронная почта already exists."
          ) {
            alert("Пользватель с такой почтой уже зарегестрирован");
          }
          return Promise.reject(error);
        });
    };

    const switchView = (view) => {
      emit("switchView", view);
    };

    return {
      email,
      message,
      resetPassword,
      switchView,
    };
  },
};
</script>
