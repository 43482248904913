<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Арбитражные дела</h5>
      <span>Арбитражные дела с участием {{ companyName }}</span>
      <ul class="info-list mt-2">
        <li class="d-flex flex-row border-bottom align-items-end pt-2">
          <span class="info-value">За все время</span
          ><span class="info-bold">{{ casesAmount }}</span>
        </li>
      </ul>
      <!-- <BarChart
        :chartData="chartData"
        :chartOptions="chartOptions"
        style="filter: blur(5px)"
      /> -->
      <button
        class="btn-primary btn-small d-block ms-auto mt-2"
        @click="emitChangeTab('tab-arbitration')"
      >
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
// import BarChart from "./Diagrams/BarChart.vue";

export default {
  name: "ArbitrationInfo",
  props: {
    companyName: String,
    casesAmount: Number,
  },
  setup(_, { emit }) {
    const emitChangeTab = (newTab) => {
      emit("change-tab", newTab); // Отправка события на уровень выше
    };
    // Данные для графика
    const chartData = {
      labels: [
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
      ],
      datasets: [
        {
          label: "Region A",
          data: [10, 20, 15, 25, 30, 35, 40, 35, 20, 15, 38, 22, 35],
          backgroundColor: "#86C2FF4D",
          borderColor: "#86C2FF",
          borderWidth: 1,
          borderRadius: 5,
          // Создаем отступы для столбцов
          barPercentage: 1,
          categoryPercentage: 0.8,
        },
        {
          label: "Region B",
          data: [12, 18, 20, 22, 28, 32, 38, 10, 20, 15, 25, 32, 35],
          backgroundColor: "#B17BFF4D",
          borderColor: "#B17BFF",
          borderWidth: 1,
          borderRadius: 5,
          // Создаем отступы для столбцов
          barPercentage: 1,
          categoryPercentage: 0.8,
        },
      ],
    };

    // Опции для графика
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Chart.js Bar Chart",
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.dataset.label + ": " + context.parsed.y;
            },
          },
        },
      },
      layout: {
        padding: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
          },
        },
        y: {
          display: true, // Скрывает ось X
          grid: {
            display: true, // Скрывает сетку по оси X
          },
          title: {
            display: false, // Скрывает заголовок оси X
          },
          ticks: {
            display: false, // Скрывает заголовок оси X
          },
        },
      },
    };
    return {
      emitChangeTab,
      chartData,
      chartOptions,
    };
  },
  components: {
    // BarChart,
  },
};
</script>
