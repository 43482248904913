<template>
  <div class="profile cart">
    <div class="card profile-info full-height">
      <div class="card-body">
        <h2>Поддержка</h2>
        <div class="products-container mt-4">
          <button
            class="black-button mt-2"
            data-bs-toggle="modal"
            data-bs-target="#supportModal"
          >
            Создать обращение
          </button>

          <SupportModal />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import SupportModal from "@/components/Modals/SupportModal.vue";

export default defineComponent({
  name: "Support",
  components: {
    SupportModal,
  },
  setup() {
    const isSupportModalOpen = ref(false);

    const openSupportModal = () => {
      isSupportModalOpen.value = true;
    };

    const closeSupportModal = () => {
      isSupportModalOpen.value = false;
    };

    return {
      isSupportModalOpen,
      openSupportModal,
      closeSupportModal,
    };
  },
});
</script>
