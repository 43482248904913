<template>
  <div class="connections mb-4 py-4">
    <div class="card pb-4">
      <div class="card-header">
        <h5 class="card-title mb-0">Виды деятельности ОКВЭД</h5>
      </div>
      <div class="card-body">
        <ul
          class="info-list mt-4 d-flex justify-content-between flex-md-row flex-column"
        >
          <li>
            <span class="info-title">Отрасль</span>
            <span class="info-value pt-2">{{ company.okved?.name }}</span>
          </li>
          <li>
            <span class="info-title">Основной вид деятельности ОКВЭД</span>
            <span class="info-value pt-2"
              >{{ company.okved?.name }} ({{ company.okved?.code }})</span
            >
          </li>
          <li>
            <span class="info-title">Регион</span>
            <span class="info-title pt-2">{{ company.region?.name }}</span>
          </li>
        </ul>

        <ul
          class="info-list mt-4 pt-4 col-md-7 d-none"
          style="column-count: 2; column-gap: 2rem"
        >
          <li><span class="info-title">Место в отрасли</span></li>
          <li class="d-flex flex-row align-items-center border-bottom mt-2">
            <span class="info-value">В России</span>
            <span class="info-value text-end"
              ><span class="info-bold text-end">#6470</span>из 71129</span
            >
          </li>
          <li class="d-flex flex-row align-items-center border-bottom pt-3">
            <span class="info-value">В регионе Ростовская область</span>
            <span class="info-value text-end"
              ><span class="info-bold text-end">#70</span>из 129</span
            >
          </li>
        </ul>
        <ul class="info-list col-md-3 mt-4 pt-4 d-none">
          <li><span class="info-title">Средняя выручка отрасли</span></li>
          <li class="d-flex flex-row align-items-center border-bottom mt-2">
            <span class="info-value">В России</span>
            <span class="info-bold text-end">30 млн.р.</span>
          </li>
        </ul>
        <ul class="info-list col-md-4 mt-4 pt-4">
          <li>
            <span class="info-title">Дополнительные виды деятельности</span>
          </li>
          <li
            class="pt-3"
            v-for="(type, index) in company.okved_add"
            :key="index"
          >
            <span class="info-value">{{ type.name }} ({{ type.code }})</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Okved",
  props: {
    company: Object,
  },
});
</script>
