<script setup>
import { ref, onMounted, reactive } from "vue";

const lang = ref(localStorage.getItem("lang"));
const languages = reactive([
  {
    name: "en",
    code: "en",
    flag: "🇺🇸",
  },
  {
    name: "ru",
    code: "ru",
    flag: "🇷🇺",
  },
  {
    name: "cn",
    code: "zh-CN",
    flag: "🇨🇳",
  },
]);

// Управление выпадающим меню
const isDropdownOpen = ref(false);

onMounted(() => loadGoogleTranslateScript());
function loadGoogleTranslateScript() {
  const script = document.createElement("script");
  script.src =
    "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  script.async = true;
  document.head.appendChild(script);
  window.googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };
}
function changeLanguage(lang) {
  localStorage.setItem("lang", lang);
  const googleTranslateComboBox = document.querySelector(".goog-te-combo");
  if (googleTranslateComboBox) {
    googleTranslateComboBox.value = lang;
  }
  window.location = `#googtrans(${lang})`;
  location.reload();
}
</script>

<template>
  <!-- <select v-model="lang" @change="changeLanguage($event.target.value)">
    <option
      v-for="language in languages"
      :key="language.code"
      :value="language.code"
    >
      {{ language.name }}
    </option>
  </select> -->
  <div class="language-selector notranslate">
    <div class="selected-language" @click="isDropdownOpen = !isDropdownOpen">
      <span>{{
        languages.find((l) => l.code === lang)?.flag || languages[0].flag
      }}</span>
      <span>{{
        languages.find((l) => l.code === lang)?.name || languages[0].name
      }}</span>
    </div>
    <ul v-show="isDropdownOpen" class="language-dropdown">
      <li
        v-for="language in languages"
        :key="language.code"
        @click="changeLanguage(language.code)"
        :class="{ active: language.code === lang }"
      >
        <span class="flag">{{ language.flag }}</span>
        <span>{{ language.name }}</span>
      </li>
    </ul>
    <ul class="language-list">
      <li
        v-for="language in languages"
        :key="language.code"
        @click="changeLanguage(language.code)"
        :class="{ active: language.code === lang }"
      >
        <span class="flag">{{ language.flag }}</span>
        <span>{{ language.name }}</span>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.skiptranslate {
  display: none !important;
}
[class*="VIpgJd"] {
  display: none !important;
}

#goog-gt- {
  display: none !important;
}
body {
  top: 0 !important;
}

.language-selector {
  position: relative;
  width: 80px;
  cursor: pointer;
  display: flex;
}

.selected-language {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  user-select: none;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  list-style: none;
  padding: 0;
  z-index: 10;
}

.language-dropdown li {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.language-dropdown li.active {
  background-color: #f0f0f0;
}

.language-dropdown li:hover {
  background-color: #e6e6e6;
}

.language-list {
  list-style: none;
  display: none;
  margin: 0;
  align-items: center;
  padding: 0;
  li {
    display: flex;
    gap: 4px;
    padding: 8px;
    cursor: pointer;
    &:not(:first-child) {
      border-left: 1px solid #ddd;
    }
  }
}
.flag {
  margin-right: auto;
}

.translated-ltr {
  /* display: none; */
}
.goog-te-spinner-pos {
  display: none !important;
}
.goog-te-banner-frame,
.goog-te-spinner-pos {
  display: none !important;
}
iframe[style*="translate.googleapis.com"] {
  display: none !important;
}
.goog-te-spinner-pos {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
</style>
