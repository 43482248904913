<template>
  <div class="marketplace mx-4 my-4 pb-4" v-if="company">
    <h1>Компания {{ company.name }}</h1>
    <div class="marketplace-header mt-4">
      <div class="marketplace-image">
        <img
          class="company"
          :src="
            company.image
              ? company.image
              : require('@/assets/images/logo_info.svg')
          "
          alt="Product Image"
        />
      </div>
      <div class="marketplace-card col-md-4 mt-4">
        <div
          class="seller-info d-flex justify-content-between align-items-center"
          @click="goToCounterParties(company.inn)"
        >
          <p class="mb-0">Посмотреть отчет о компании</p>
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.332928 0.292786C0.122023 0.480314 0.0035429 0.734622 0.0035429 0.999786C0.0035429 1.26495 0.122023 1.51926 0.332928 1.70679L5.90168 6.65679L0.332928 11.6068C0.128 11.7954 0.0146065 12.048 0.01717 12.3102C0.0197334 12.5724 0.138048 12.8232 0.346632 13.0086C0.555216 13.194 0.83738 13.2992 1.13235 13.3015C1.42732 13.3037 1.7115 13.2029 1.92368 13.0208L8.2878 7.36379C8.49871 7.17626 8.61719 6.92195 8.61719 6.65679C8.61719 6.39162 8.49871 6.13731 8.2878 5.94979L1.92368 0.292786C1.71271 0.105315 1.42661 0 1.1283 0C0.829993 0 0.543897 0.105315 0.332928 0.292786Z"
              fill="#3E3E3E"
            />
          </svg>

          <!-- <button>Написать</button> -->
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="marketplace-specs-section row mt-4">
      <div class="specs col-6">
        <table class="mt-4">
          <tr>
            <td><span>Сайт</span></td>
            <td>{{ company.contacts.web_site }}</td>
          </tr>
          <tr>
            <td><span>ИНН</span></td>
            <td>{{ company.inn }}</td>
          </tr>
        </table>
      </div>
    </div>
    <!-- <div class="divider"></div>
    <h3>Карточки компании</h3>
    <div class="product-list row gx-4 gy-4">
      <ProductCard
        class="col-md-4"
        v-for="(item, index) in products"
        :key="index"
        :product="item.product"
      />
    </div> -->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
// import ProductCard from "../../components/Marketplace/ProductCard.vue";
import MarketplaceService from "../../services/marketplace";

export default defineComponent({
  name: "Company",
  // components: { ProductCard },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const company = ref(null);
    const products = ref([]);

    const goToCounterParties = () => {
      router.push({
        name: "CounterParties",
        params: { id: company.value.inn },
      });
    };

    // const getProducts = async () => {
    //   const me = await store.dispatch("user/me");

    //   MarketplaceService.companyRepresentative(
    //     me.id,
    //     route.query.page || 1
    //   ).then((res) => {
    //     products.value = res;
    //   });
    // };

    onMounted(async () => {
      const companyId = route.params.id;
      if (companyId) {
        company.value = await store.dispatch("companies/getById", companyId);
      }
    });

    return {
      company,
      goToCounterParties,
      // getProducts,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/marketplace.scss";
</style>
