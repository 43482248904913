<template>
  <div class="card main-info">
    <div class="card-body">
      <h5 class="card-title">Основная информация</h5>
      <ul class="info-list">
        <li class="pt-3">
          <span class="info-title">Основной вид деятельности:</span>
          <span class="info-value">{{ company.okved?.name }}</span>
        </li>
        <div class="d-flex pt-3">
          <li class="col-4">
            <span class="info-title">ИНН:</span>
            <span class="info-value" style="position: relative"
              >{{ company.inn }}
              <div class="hint" v-if="showHint[0]">{{ hint[0] }}</div>
              <img
                @mouseover="showHint[0] = true"
                @mouseleave="showHint[0] = false"
                class="copy ms-2"
                src="@/assets/images/icons/copy.svg"
                @click="copyText(company.inn, 0)"
            /></span>
          </li>
          <li class="col-4" v-if="company.kpp">
            <span class="info-title">КПП:</span>
            <span class="info-value">{{ company.kpp }}</span>
          </li>
          <li class="col-4">
            <span class="info-title">ОГРН:</span>
            <span class="info-value" style="position: relative"
              >{{ company.ogrn }}
              <div class="hint" v-if="showHint[1]">{{ hint[1] }}</div>
              <img
                @mouseover="showHint[1] = true"
                @mouseleave="showHint[1] = false"
                class="copy ms-2"
                src="@/assets/images/icons/copy.png"
                @click="copyText(company.ogrn, 1)"
            /></span>
          </li>
        </div>
        <div class="d-flex pt-3">
          <li class="col-6">
            <span class="info-title">Дата регистрации:</span>
            <span class="info-value">{{
              formatDate(company.registration_date)
            }}</span>
          </li>
          <li class="col-6" v-if="company.share_capital">
            <span class="info-title">Уставный капитал:</span>
            <span class="info-value">{{ company.share_capital?.total }}</span>
          </li>
        </div>
        <li class="pt-3">
          <span class="info-title">Юридический адрес:</span>
          <span class="info-value"
            >{{ company.legal_adress?.adress || company.city }}
          </span>
        </li>
        <li class="pt-3">
          <span class="info-title">Руководитель:</span>
          <span class="info-value">{{
            company.directors?.length ? company.directors[0]?.fio : "Неизвестно"
          }}</span>
        </li>
        <!-- <li class="pt-3">
          <span class="info-title">Держатель реестров акционеров:</span>
          <span class="info-value">Акционерное общество АО</span>
        </li> -->
        <li class="pt-3">
          <span class="info-title">Среднесписочная численность:</span>
          <span class="info-value">нет данных</span>
        </li>
        <li class="pt-3">
          <span class="info-title">Специальный налоговый режим:</span>
          <span class="info-value">Не применяется</span>
        </li>
        <div class="d-flex pt-3">
          <li class="col-6">
            <span class="info-title">Реестр МСП:</span>
            <span class="info-value">Не применяется</span>
          </li>
          <li class="col-6">
            <span class="info-title">Место в отрасли:</span>
            <span class="info-value">Не применяется по выручке</span>
          </li>
        </div>
        <li class="pt-3">
          <span class="info-title">Налоговый орган:</span>
          <span class="info-value">{{ company.taxAuthority?.name }} </span>
        </li>
        <li class="py-3">
          <span class="info-title">Коды статистики:</span>
          <span class="info-value"
            >ОКПО <strong>{{ company.okpo?.code || company.okpo }}</strong>
            <br />ОКАТО <strong>{{ company.okato?.code }}</strong> <br />ОКТМО
            <strong>{{ company.oktmo?.code }}</strong> <br />ОКФС
            <strong>{{ company.okfs?.code }}</strong> <br /><strong>{{
              company.okfs?.name
            }}</strong>
            <br />ОКОГУ <strong>{{ company.okogu?.code }}</strong> <br />{{
              company.okogu?.name
            }}
            <br />ОКОПФ
            <strong>{{ company.okopf?.code }}</strong>
          </span>
        </li>
        <div class="divider"></div>
        <!-- <li class="py-3">
          <span class="info-value"
            >По организации доступны <a href="#">исторические сведения 777</a
            ><br /><a href="#">Это ваша компания ?</a></span
          >
        </li> -->
        <div class="divider"></div>

        <!-- <li class="pt-3">
          <span class="info-value">Актуально на 21.08.2024</span>
        </li> -->
        <li class="pt-3">
          <span class="info-value"
            >Председатель правления {{ company.name || company.fio }} -
            {{
              company.directors?.length
                ? company.directors[0]?.fio
                : company.fio
            }}
            (ИНН
            {{
              company.directors?.length
                ? company.directors[0].inn
                : company.inn
            }}). Организации присвоен ИНН {{ company.inn }}, ОГРН
            {{ company.ogrn }}</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import company from "@/services/company";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import moment from "moment";

export default defineComponent({
  name: "MainInfo",
  props: {
    company: Object,
  },
  setup(props) {
    const store = useStore();
    const showHint = ref([false, false]);
    const hint = ref(["Скопировать", "Скопировать"]);
    const copyText = (textToCopy, index) => {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          console.log("Текст скопирован в буфер обмена!");
          hint.value[index] = "Скопировано!";
          setTimeout(() => {
            hint.value[index] = "Скопировать";
          }, 2000);
        })
        .catch((err) => {
          console.error("Ошибка копирования текста: ", err);
        });
    };

    const formatDate = (date) => {
      return moment(date).format("DD.MM.YYYY");
    };

    return {
      showHint,
      hint,
      copyText,
      formatDate,
    };
  },
});
</script>
