<template>
  <div class="mobilebar-profile">
    <nav class="menu">
      <ul>
        <li v-for="(item, index) in sidebarList" :key="index">
          <router-link :to="item.link" class="menu-link" active-class="active">
            <img :src="item.icon" />
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { sidebarList } from "@/models/sidebar";

export default defineComponent({
  name: "MobileBar",
  setup() {
    const router = useRouter();

    return {
      sidebarList,
      router,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/profile/mobilebar.scss";
</style>
