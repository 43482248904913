<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Анализ надежности</h5>
      </div>
      <div class="card-body">
        <ul class="info-list d-md-flex col-md-9">
          <li class="d-flex flex-row col-md-3 col-12 align-items-center">
            <span class="info-title">Все факторы</span>
            <span class="info-value text-end">{{
              prosFactors?.length + consFactors?.length || 0
            }}</span>
          </li>
          <li
            class="d-flex flex-row col-md-3 col-12 align-items-center ms-md-4"
          >
            <span class="info-value d-flex align-items-center">
              <div class="circle success me-3"></div>
              Положительные</span
            >
            <span class="text-end">{{ prosFactors?.length || 0 }}</span>
          </li>
          <li
            class="d-flex flex-row col-md-3 col-12 align-items-center ms-md-4"
          >
            <span class="info-value d-flex align-items-center">
              <div class="circle decline me-3"></div>
              Отрицательные</span
            >
            <span class="text-end">{{ consFactors?.length || 0 }}</span>
          </li>
        </ul>
        <div
          class="accordion mt-4"
          id="accordionOne"
          v-if="prosFactors?.length"
        >
          <div
            class="accordion-item px-2"
            v-for="(factor, index) in prosFactors"
            :key="index"
          >
            <h2 class="accordion-header" :id="'heading' + index">
              <button
                @click="toggleCollapse(index)"
                class="accordion-button collapsed"
                type="button"
                :data-bs-toggle="'collapse'"
                :data-bs-target="'#collapse' + index"
                aria-expanded="false"
                :aria-controls="'collapse' + index"
              >
                <div class="circle me-3" :class="factor.status"></div>
                {{ factor.title }} :
              </button>
            </h2>
            <div
              :id="'collapse' + index"
              class="accordion-collapse collapse"
              :class="{ show: isOpen === index }"
              :aria-labelledby="'heading' + index"
              data-bs-parent="#accordionOne"
            >
              <div class="accordion-body">
                {{ factor.text }}
              </div>
            </div>
          </div>
        </div>
        <div v-else>Положительных факторов не обнаружено</div>

        <div
          class="accordion mt-4"
          id="accordionTwo"
          v-if="consFactors?.length"
        >
          <div
            class="accordion-item px-2"
            v-for="(factor, index) in consFactors"
            :key="index"
            :id="'heading-two' + index"
          >
            <h2 class="accordion-header" id="headingOne">
              <button
                @click="toggleCollapse(index)"
                class="accordion-button collapsed"
                type="button"
                :data-bs-toggle="'collapse-two'"
                :data-bs-target="'#collapse-two' + index"
                :aria-expanded="isOpen === index ? 'true' : 'false'"
                :aria-controls="'collapse-two' + index"
              >
                <div class="circle me-3" :class="factor.status"></div>
                {{ factor.title }} :
              </button>
            </h2>
            <div
              :id="'collapse-two' + index"
              class="accordion-collapse collapse"
              :class="{ show: isOpen === index }"
              :aria-labelledby="'heading-two' + index"
              data-bs-parent="#accordionTwo"
            >
              <div class="accordion-body">
                {{ factor.text }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mt-2">Нарушений не обнаружено</div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Analyze",
  props: {
    company: Object,
  },
  setup(props) {
    const isOpen = ref(null);

    const isCollapsed = ref(true);
    const prosFactors = ref([]);
    const consFactors = ref([]);

    function toggleCollapse() {
      isCollapsed.value = !isCollapsed.value;
    }

    function getParsedData(number, factors, status) {
      let i = 0;
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        props.company.html_checko,
        "text/html"
      );
      const rating = doc.getElementById("rating-switcher");
      const divs = rating?.querySelectorAll("div");
      if (number === 1) {
        i = divs?.length - 1;
      }
      const firstUl = rating
        ?.querySelectorAll("ul")
        [number]?.querySelectorAll(`li`)
        ? rating?.querySelectorAll("ul")[number].querySelectorAll(`li`)
        : rating?.querySelectorAll("div")[i] || null;

      if (firstUl?.length) {
        firstUl?.forEach((li, index) => {
          const div = li.querySelector(".uk-text-bold");
          const textWithoutSpan = div
            ? [...div.childNodes]
                .filter((node) => node.nodeType === Node.TEXT_NODE)
                .map((node) => node.textContent)
                .join("")
                .trim()
            : null;

          const listItemObject = {
            id: index + 1,
            title: textWithoutSpan,
            text: li.textContent.trim(),
            status: status,
          };

          factors.push(listItemObject);
        });
      }
    }
    if (props.company.html_checko) {
      getParsedData(0, prosFactors.value, "success");
      getParsedData(1, consFactors.value, "decline");
    }

    return {
      isOpen,
      prosFactors,
      consFactors,
      toggleCollapse,
    };
  },
});
</script>

<style scoped>
.collapse {
  transition: height 0.35s ease;
}
</style>
