<template>
  <div class="product-card" @click="goToItem()">
    <img
      class="product-image"
      :src="
        product.images?.length
          ? product.images[0]?.image
          : require('@/assets/images/logo_info.svg')
      "
      alt=""
    />
    <button
      @click.stop="clickLike"
      class="like-button"
      :class="{ liked: likedProduct === true }"
    >
      <svg
        width="35"
        height="30"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.18054 13.5549L7.17983 13.5542C5.11017 11.6365 3.43202 10.0786 2.26545 8.62023C1.10468 7.16915 0.5 5.8759 0.5 4.49591C0.5 2.23655 2.22166 0.5 4.4 0.5C5.6368 0.5 6.83521 1.09095 7.61714 2.02186L8 2.47767L8.38286 2.02186C9.16479 1.09095 10.3632 0.5 11.6 0.5C13.7783 0.5 15.5 2.23655 15.5 4.49591C15.5 5.8759 14.8953 7.16915 13.7346 8.62023C12.568 10.0786 10.8898 11.6365 8.82017 13.5542L8.81946 13.5549L8 14.3171L7.18054 13.5549Z"
          stroke="#313132"
        />
      </svg>
    </button>
    <div class="product-info d-flex">
      <div class="col-10">
        <h3>{{ formatPrice(product.sale_price) }}</h3>
        <p>{{ product.name }}</p>
      </div>
      <div class="rating">★ {{ product.rating }}</div>
    </div>
    <button
      class="btn btn-primary btn-light w-100"
      :disabled="productAdded"
      @click.stop="addToCart"
    >
      {{ productAdded ? "В корзине" : "Добавить в корзину" }}
    </button>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import MarketplaceService from "@/services/marketplace";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "ProductCard",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const productAdded = ref(false);
    const likedProduct = ref(props.product.is_liked);
    const isLoggedIn = computed(() => store.state.auth.status.loggedIn);

    const clickLike = async () => {
      if (isLoggedIn.value) {
        if (!likedProduct.value) {
          await MarketplaceService.addToFavorite(props.product.id);
          likedProduct.value = true;
        } else {
          await MarketplaceService.removeFromFavorite(props.product.id);
          likedProduct.value = false;
        }
      } else {
        openModal();
      }
    };

    const openModal = () => {
      const loginModal = new Modal(document.getElementById("authModal"));
      loginModal.show();
    };

    const addToCart = async () => {
      if (isLoggedIn.value) {
        await MarketplaceService.addToBasket(props.product.id, 1);
        store.dispatch("user/getCartItems");

        productAdded.value = true;
        setTimeout(() => {
          productAdded.value = false;
        }, 1000);
      } else {
        openModal();
      }
    };

    const goToItem = () => {
      router.push({
        name: "MarketplaceItem",
        params: { id: props.product.id },
      });
    };

    const formatPrice = (price) => {
      return new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(price);
    };

    return {
      goToItem,
      clickLike,
      addToCart,
      formatPrice,
      likedProduct,
      productAdded,
      router,
    };
  },
});
</script>

<style scoped lang="scss">
p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.like-button {
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  padding: 0;
  border: none;
  cursor: pointer;
  svg {
    transition: all 0.2s;
    fill: #fff;
    path {
      stroke: #fff;
    }
  }
  background-color: inherit;

  &.liked {
    svg {
      fill: #d44233;
      path {
        stroke: #d44233;
      }
    }
  }
}
.product-card {
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

.product-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 30px;
}

.product-info {
  margin-top: 10px;
}

.rating {
  color: #ffc107;
  margin-bottom: 10px;
}
</style>
