import api from "./api";
import checkoApi from "./checkoApi";
import { Company, CompanyFinances } from "../models/types";
import { AxiosResponse } from "axios";
import TokenService from "./token";

interface FriendForm {
  friend: number;
}

class CompanyService {
  async getFriends(): Promise<Company[]> {
    const res = await api.get("/api/v1/friends/");
    return res.data;
  }

  addFriend(form: FriendForm): Promise<AxiosResponse> {
    return api
      .post(`/api/v1/friends/`, {
        ...form,
      })
      .then((res: AxiosResponse) => {
        return res;
      });
  }

  removeFriend(id: string): Promise<void> {
    return api
      .delete(`/api/v1/friends/${id}`)
      .then((res: AxiosResponse<void>) => {
        return res.data;
      });
  }
  // Получить все компании
  async all(): Promise<Company[]> {
    const res = await api.get("/api/v1/companies/");
    return res.data;
  }

  // Получить определенную компании
  async getById(id: number): Promise<Company[]> {
    const res = await api.get(`/api/v1/companies/${id}`);
    return res.data;
  }

  // Привязка к компании
  addCompany(form: any): Promise<AxiosResponse> {
    console.log(form);
    return api
      .post(`/api/v1/ticket-company-binding/`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: AxiosResponse) => {
        return res;
      });
  }

  // Получить компании по представителю
  async getCompany(id: number): Promise<Company[]> {
    const res = await api.get(`/api/v1/companies/?representative__in=${id}`);
    return res.data;
  }

  // Получить компанию по ИНН
  async getByInn(inn: string): Promise<Company> {
    const res = await api.get("/api/v1/company/", {
      params: {
        query: inn,
      },
    });
    return res.data;
  }

  // Получить финансовые данные компании по ИНН
  async getFinances(inn: string): Promise<CompanyFinances> {
    const res = await api.get("/api/v1/company-finances/", {
      params: {
        query: inn,
      },
    });
    return res.data;
  }

  async getLegalCases(inn: string): Promise<CompanyFinances> {
    const res = await api.get("/api/v1/legal-cases/", {
      params: {
        inn: inn,
      },
    });
    return res.data;
  }

  async getContracts(inn: string): Promise<CompanyFinances> {
    const res = await api.get("/api/v1/contracts/", {
      params: {
        inn: inn,
      },
    });
    return res.data;
  }

  async getInspections(inn: string): Promise<CompanyFinances> {
    const res = await api.get("/api/v1/inspection/", {
      params: {
        inn: inn,
      },
    });
    return res.data;
  }
  // Получить предпринимателя по ИП
  async getByIP(ip: string): Promise<Company> {
    const res = await api.get("/api/v1/entrepreneur/", {
      params: {
        query: ip,
      },
    });
    return res.data;
  }

  // Поиск компаний через checko API
  async searchCompanies(query: string): Promise<Company[]> {
    const apiKey = "aqINaoVi4IbvAqkP";
    const companies1Res = await checkoApi.get(
      `/search/?key=${apiKey}&by=name&obj=org&query=${query}`
    );
    const companies1 = companies1Res.data.data["Записи"];

    const companies2Res = await checkoApi.get(
      `/search/?key=${apiKey}&by=founder-name&obj=org&query=${query}`
    );
    const companies2 = [...companies1, ...companies2Res.data.data["Записи"]];

    const companies3Res = await checkoApi.get(
      `/search/?key=${apiKey}&by=leader-name&obj=org&query=${query}`
    );
    const companies3 = [...companies2, ...companies3Res.data.data["Записи"]];

    const companies4Res = await checkoApi.get(
      `/search/?key=${apiKey}&by=okved&obj=org&query=${query}`
    );
    const companies4 = [...companies3, ...companies4Res.data.data["Записи"]];
    return companies4;
  }

  // Поиск компаний через основной API
  async companySearch(query: string): Promise<Company[]> {
    const res = await api.get("/api/v1/company-search", {
      params: {
        query,
      },
    });
    return res.data;
  }
}

export default new CompanyService();
