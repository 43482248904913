import { ref } from "vue";

import profile from "../assets/images/icons/profile.svg";
import friends from "../assets/images/icons/friends.svg";
import products from "../assets/images/icons/products.svg";
import orders from "../assets/images/icons/orders.svg";
import reviews from "../assets/images/icons/reviews.svg";
import favorites from "../assets/images/icons/favorites.svg";
import messages from "../assets/images/icons/messages.svg";
import basket from "../assets/images/icons/basket.svg";
import support from "../assets/images/icons/support_icon.svg";

import { Sidebar } from "./types";

export const sidebarList = ref<Sidebar[]>([
  {
    icon: profile,
    title: "Профиль",
    link: "/profile/info",
  },
  //   {
  //     icon: company,
  //     title: "Компания",
  //     link: "/profile/company",
  //   },
  {
    icon: products,
    title: "Карточка",
    link: "/profile/products",
  },
  {
    icon: friends,
    title: "Друзья",
    link: "/profile/friends",
  },
  {
    icon: orders,
    title: "Заказы",
    link: "/profile/orders",
  },
  {
    icon: reviews,
    title: "Отзывы",
    link: "/profile/reviews",
  },
  {
    icon: favorites,
    title: "Избранное",
    link: "/profile/favorites",
  },
  {
    icon: messages,
    title: "Сообщения",
    link: "/profile/messages",
  },
  {
    icon: basket,
    title: "Корзина",
    link: "/profile/basket",
  },
  {
    icon: support,
    title: "Поддержка",
    link: "/profile/support",
  },
]);
