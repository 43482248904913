import api from "./api";

class productCategoriesService {
  all() {
    return api.get("/api/v1/categories/").then((res) => {
      return res.data;
    });
  }
  tags() {
    return api.get("/api/v1/tags/").then((res) => {
      return res.data;
    });
  }
  addTags(data: any) {
    return api.post("/api/v1/tags/", data).then((res) => {
      console.log(res);
      return res.data;
    });
  }
}

export default new productCategoriesService();
