
import { defineComponent, computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useDebounceFn } from "@vueuse/core";

export default defineComponent({
  name: "SearchComponent",
  setup() {
    const isFocused = ref(false);
    const searchQuery = ref("");
    const filteredResults = ref<any[]>([]);
    const router = useRouter();
    const store = useStore();
    const isLoading = ref(false);
    const selectMode = ref("start");
    const searchResultsINN = ref([]);
    const searchResultsIP = ref([]);

    const goToCompany = (companyId: string) => {
      isFocused.value = false;
      router.push("/counter-parties/" + companyId);
    };

    const searchCompany = useDebounceFn(async () => {
      isFocused.value = true;
      if (searchQuery.value) {
        // await store.dispatch("companies/searchCompanies", searchQuery.value);
        store
          .dispatch("companies/searchCompanies", searchQuery.value)
          .then((companies) => {
            selectMode.value = "start";
            searchResultsIP.value = [];
            searchResultsINN.value = [];
            searchResultsIP.value = companies[0]["Записи"] || companies;
            if (companies?.at(1)) {
              searchResultsINN.value = companies?.at(1)["Записи"];
            }
            isLoading.value = false;
          });
      }
    }, 1000);

    const onSearch = () => {
      isLoading.value = true;
      searchCompany();
    };

    return {
      goToCompany,
      onSearch,
      // companies,
      searchQuery,
      filteredResults,
      isFocused,
      isLoading,
      selectMode,
      searchResultsIP,
      searchResultsINN,
    };
  },
});
