<template>
  <div
    class="modal fade crop-modal"
    id="cropModal"
    tabindex="-1"
    aria-labelledby="cropModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          Обрезать изображение
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          ></button>
        </div>

        <div v-if="newAvatar" class="d-flex justify-content-center py-4">
          <!-- Изображение для кроппера -->
          <img
            :src="newAvatar"
            alt="Image for cropping"
            ref="imageElement"
            style="max-width: 100%"
          />
        </div>
        <div class="modal-footer">
          <button @click="closeModal" class="grey-button">Отмена</button>
          <button @click="cropImage" class="black-button">Обрезать</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, watch, nextTick } from "vue";
import UserService from "@/services/user";
import { useStore } from "vuex";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "CropModal",
  components: {},
  props: {
    newAvatar: File,
  },
  setup(props) {
    const store = useStore();
    const imageElement = ref(null);
    const croppedImage = ref(null);
    let cropper = null;
    const closeButton = ref(null);

    const closeModal = () => {
      cropper.destroy();
      cropper = null;
      closeButton.value.click();
    };

    const initCropper = () => {
      if (imageElement.value) {
        cropper = new Cropper(imageElement.value, {
          aspectRatio: 1, // Соотношение сторон 1:1 для аватара
          viewMode: 1,
          autoCropArea: 1,
          responsive: true,
          scalable: true,
          zoomable: true,
          movable: true,
          guides: false, // Отключаем направляющие
          minContainerHeight: 400, // Минимальная высота контейнера
          minContainerWidth: 400,
        });
      }
    };

    const cropImage = async () => {
      if (cropper) {
        const canvas = cropper.getCroppedCanvas({
          width: 300,
          height: 300,
        });

        croppedImage.value = canvas.toDataURL("image/png"); // Для Base64
        canvas.toBlob(async (croppedBlob) => {
          const avatarFormData = new FormData();
          avatarFormData.append("avatar", croppedBlob, "avatar.png");

          await UserService.updateAvatar(avatarFormData);
          await store.dispatch("user/me");
          closeModal();
        }, "image/png");
      }
    };
    watch(
      () => props.newAvatar,
      (newValue) => {
        if (newValue) {
          nextTick(() => {
            initCropper(); // Инициализация cropper после рендера изображения
          });
        }
      }
    );
    return {
      croppedImage,
      imageElement,
      closeButton,
      cropImage,
      closeModal,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/auth.scss";
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%; /* Делаем рамку круглой */
}

.cropper-face {
  background-color: rgba(
    255,
    255,
    255,
    0.6
  ); /* Цвет заливки для области кропа */
}
</style>
