<template>
  <div class="header__bar">
    <div
      class="header__burger"
      :class="{ burgerActive: openBurger === true }"
      @click="toggleBurger"
    >
      <span class="line"></span>
    </div>
    <ul class="burger" v-if="openBurger">
      <!-- <router-link to="/" class="burger__logo mx-auto">
        <img src="@/assets/images/logo.svg" />
      </router-link> -->

      <li class="burger-item">
        <router-link to="/">Главная</router-link>
      </li>
      <li class="burger-item">
        <router-link to="/marketplace">Маркетплейс</router-link>
      </li>
      <li class="burger-item">
        <router-link :to="{ path: '/', hash: '#pricing-section' }"
          >Тарифы</router-link
        >
      </li>
      <li class="burger-item">
        <router-link :to="{ path: '/', hash: '#footer-section' }"
          >Реквизиты</router-link
        >
      </li>
      <li class="burger-item">
        <router-link to="/blog">Блог</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "Burger",
  setup() {
    const openDropdown = ref(false);
    const openBurger = ref(false);
    const route = useRoute();
    const toggleBurger = () => {
      openBurger.value = !openBurger.value;
    };
    const toggleDropdown = () => {
      if (!openDropdown.value) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "auto";
      }
      openDropdown.value = !openDropdown.value;
    };
    watch(route, () => {
      openDropdown.value = false;
      openBurger.value = false;
      document.documentElement.style.overflow = "auto";
    });
    return {
      openBurger,
      openDropdown,
      toggleBurger,
      toggleDropdown,
    };
  },
});
</script>
