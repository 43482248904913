<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Связи</h5>
      <template v-if="connections?.length">
        <span>Выявлено {{ connections.length }} связанных организаций</span>
        <ul class="info-list mt-2">
          <li>
            <span class="info-title">Действующие :</span>
          </li>
          <li class="d-flex flex-row border-bottom align-items-end pt-2">
            <span class="info-value">Всего</span
            ><span class="info-bold">{{ activeConnections?.length }}</span>
          </li>
          <li class="pt-3">
            <span class="info-title">Прошлые:</span>
          </li>

          <li class="d-flex flex-row border-bottom align-items-end pt-2">
            <span class="info-value">Всего</span
            ><span class="info-bold">{{
              connections.length - activeConnections?.length
            }}</span>
          </li>
        </ul>
      </template>
      <template v-else><p>Нет данных</p> </template>
      <button
        class="btn-primary btn-small d-block ms-auto mt-2"
        @click="emitChangeTab('tab-connections')"
      >
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "ConnectionsInfo",
  props: {
    connections: Array,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props, { emit }) {
    const emitChangeTab = (newTab) => {
      emit("change-tab", newTab);
    };
    const activeConnections = computed(() => {
      return props.connections.filter(
        (connection) => connection.status === "Действует"
      );
    });

    return {
      activeConnections,
      emitChangeTab,
    };
  },
};
</script>
