<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Госзакупки</h5>
      <template v-if="contracts?.length">
        <ul class="info-list mt-2">
          <li
            class="border-bottom py-2"
            v-for="contract in contracts.slice(0, 5)"
            :key="contract.id"
          >
            <a :href="`/counter-parties/${contract.customer.inn}`">{{
              contract.customer.name
            }}</a>
            <span class="info-value"
              >{{ contract.purchase_objects?.length || 0 }} контрактов на сумму
              {{ formatPrice(contract.price) || 0 }}
            </span>
          </li>
        </ul>
      </template>
      <template v-else><p>Нет данных</p> </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PurchasesInfo",
  props: {
    contracts: Array,
  },
  setup(props) {
    const formatPrice = (price) => {
      return new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
      }).format(price);
    };

    return {
      formatPrice,
    };
  },
};
</script>
