<template>
  <div>
    <canvas ref="lineChartCanvas"></canvas>
  </div>
</template>

<script>
export default {
  name: "LineChart",
};
</script>
<script setup>
import { ref, onMounted, onUnmounted, defineProps } from "vue";
import {
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Chart from "chart.js/auto";

// Register components
Chart.register(LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const props = defineProps({
  finances: Object,
});

const lineChartCanvas = ref(null);
let chartInstance = null;

const initChart = () => {
  if (chartInstance) {
    chartInstance.destroy();
  }

  if (lineChartCanvas.value) {
    const ctx = lineChartCanvas.value?.getContext("2d");
    const labels = props.finances.finances_years.map((item) => item.year);
    const data = props.finances.finances_years?.map((item) =>
      item.amount.reduce((sum, num) => sum + parseFloat(num) / 1000, 0)
    );
    chartInstance = new Chart(ctx, {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Количество",
            data: data,
            borderColor: "#42A5F5",
            backgroundColor: "rgba(66, 165, 245, 0.2)",
            borderWidth: 2,
            // fill: true,
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return `${context.dataset.label}: ${context.raw}`;
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
          y: {
            title: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
          r: {
            ticks: {
              callback: function () {
                return null;
              },
            },
          },
        },
      },
    });
  }
};

const checkVisibility = (entries) => {
  if (entries[0]?.isIntersecting) {
    initChart();
  }
};

onMounted(() => {
  const observer = new IntersectionObserver(checkVisibility, {
    threshold: 0.1,
  });
  if (lineChartCanvas.value) {
    observer.observe(lineChartCanvas.value);
  }

  onUnmounted(() => {
    if (lineChartCanvas.value) {
      if (lineChartCanvas.value && lineChartCanvas.value instanceof Element) {
        observer.unobserve(lineChartCanvas.value);
      }
    }
    if (chartInstance) {
      chartInstance.destroy();
    }
  });
});
</script>
