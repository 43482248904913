<template>
  <div>
    <h5 class="modal-title">Авторизация</h5>
    <form @submit.prevent="login" class="mt-4">
      <div class="mb-3">
        <label for="email" class="form-label">E-mail</label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model="user.email"
          required
        />
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Пароль</label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="user.password"
          autocomplete="current-password"
          required
        />
      </div>
      <div class="form-check d-flex align-items-center">
        <input
          type="checkbox"
          class="form-check-input"
          id="rememberMe"
          v-model="user.rememberMe"
        />
        <label class="form-check-label ms-2" for="rememberMe">
          Запомнить пароль
        </label>
      </div>
      <div class="d-flex justify-content-between py-3">
        <button
          type="button"
          class="btn btn-link"
          @click="switchView('ForgotPasswordView')"
        >
          Забыли пароль?
        </button>
        <button type="submit" class="btn btn-primary" :disabled="!isFormValid">
          <template v-if="!isLoading"> Войти </template>
          <div v-else class="loader mx-auto my-0"></div>
        </button>
      </div>

      <div class="alert" role="alert" v-if="authError">
        Неверный логин или пароль
      </div>
      <div class="divider"></div>
      <p class="mt-3 mb-1">У вас нет аккаунта?</p>
      <button
        type="button"
        class="btn btn-link"
        @click="switchView('RegisterView')"
      >
        Регистрация
      </button>
    </form>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "AuthView",
  setup(_, { emit }) {
    const isLoading = ref(false);
    const store = useStore();
    // const isRemember = ref(false);

    const user = ref({
      email: "",
      password: "",
      rememberMe: false,
    });

    const authError = ref(false);
    const isFormValid = computed(() => {
      return user.value.email && user.value.password;
    });

    const login = async () => {
      isLoading.value = true;
      if (!isFormValid.value) {
        alert("Пожалуйста, заполните все поля и примите условия.");
        return;
      }
      try {
        await store.dispatch("auth/login", user.value);
        await store.dispatch("user/me");
        isLoading.value = false;
        emit("close");
      } catch (error) {
        console.log("error", error);
        isLoading.value = false;
        authError.value = true;
      }
    };

    const switchView = (view) => {
      emit("switchView", view);
    };

    return {
      authError,
      user,
      // isRemember,
      isFormValid,
      isLoading,
      login,
      switchView,
    };
  },
};
</script>
