<template>
  <div class="marketplace mx-md-4 my-md-4" v-if="product">
    <h1 class="d-none d-md-block">{{ product.name }}</h1>
    <div
      class="marketplace-header d-lg-flex mt-md-4 pt-md-4 md-0 pt-0 d-md-block"
    >
      <div class="marketplace-image">
        <img :src="mainImg" alt="Product Image" />
      </div>
      <div class="my-carousel">
        <Swiper
          :slides-per-view="1"
          :space-between="10"
          :pagination="{ clickable: true }"
          loop
          autoplay
          :modules="[Pagination, Autoplay]"
        >
          <SwiperSlide v-for="(img, index) in product.images" :key="index">
            <img :src="img.image" alt="slide image" class="swiper-slide-img" />
          </SwiperSlide>
          <div class="swiper-pagination"></div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </Swiper>
      </div>
      <div class="marketplace-info mt-md-0 mt-4">
        <h2 class="marketplace-price mobile">
          <template v-if="product.sale_price">{{
            formatPrice(product.sale_price)
          }}</template
          ><span class="ms-2" :class="product.sale_price ? 'sale' : ''">{{
            formatPrice(product.price)
          }}</span>
        </h2>
        <h3 class="d-md-none d-block">{{ product.name }}</h3>
        <div class="rating">
          <span v-if="!product.rating" class="stars me-2">★</span>
          <span v-for="star in product.rating" :key="star" class="stars me-2"
            >★</span
          >
          <span class="ms-2">{{ product.rating }}</span>
        </div>
        <div class="marketplace-details row gy-2 gap-3">
          <img
            class="small_imgs"
            v-for="(img, index) in product.images"
            :key="index"
            :src="img.image"
            @click="mainImg = img.image"
            alt="Product Image"
          />
        </div>
        <h3>О товаре</h3>
        <table class="marketplace-specs">
          <tr>
            <td><span>Тип</span></td>
            <td>{{ product.category?.name }}</td>
          </tr>
          <!-- <tr>
            <td>Состав</td>
            <td></td>
          </tr>
          <tr>
            <td>Размер</td>
            <td></td>
          </tr>
          <tr>
            <td>Объем</td>
            <td></td>
          </tr>
          <tr>
            <td>Материал</td>
            <td></td>
          </tr>
          <tr>
            <td>Декоративные элементы</td>
            <td></td>
          </tr> -->
        </table>
      </div>
      <div class="col mt-md-0 mt-4">
        <div class="mobile-buttons">
          <button
            class="primary-button black-button mt-3 mr-3 w-100"
            :disabled="productAdded"
            @click.stop="addToCart"
          >
            {{ productAdded ? "В корзине" : "Добавить в корзину" }}
          </button>
          <button
            class="primary-button black-button mt-3 w-100"
            @click.stop="createChat"
          >
            Написать продавцу
          </button>
        </div>
        <div class="marketplace-card d-md-block d-none">
          <div class="d-flex justify-content-between">
            <h3 class="marketplace-price">
              <template v-if="product.sale_price">{{
                formatPrice(product.sale_price)
              }}</template
              ><span class="ms-2" :class="product.sale_price ? 'sale' : ''">{{
                formatPrice(product.price)
              }}</span>
            </h3>
            <button
              @click="clickLike"
              class="like-button"
              :class="{ liked: likedProduct === true }"
            >
              <svg
                width="35"
                height="30"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.18054 13.5549L7.17983 13.5542C5.11017 11.6365 3.43202 10.0786 2.26545 8.62023C1.10468 7.16915 0.5 5.8759 0.5 4.49591C0.5 2.23655 2.22166 0.5 4.4 0.5C5.6368 0.5 6.83521 1.09095 7.61714 2.02186L8 2.47767L8.38286 2.02186C9.16479 1.09095 10.3632 0.5 11.6 0.5C13.7783 0.5 15.5 2.23655 15.5 4.49591C15.5 5.8759 14.8953 7.16915 13.7346 8.62023C12.568 10.0786 10.8898 11.6365 8.82017 13.5542L8.81946 13.5549L8 14.3171L7.18054 13.5549Z"
                  stroke="#313132"
                />
              </svg>
            </button>
          </div>
          <button
            class="primary-button black-button mt-3 me-3 w-50"
            :disabled="productAdded"
            @click.stop="addToCart"
          >
            {{ productAdded ? "В корзине" : "Добавить в корзину" }}
          </button>
          <button
            class="primary-button black-button mt-3 w-50"
            @click.stop="createChat"
          >
            Написать продавцу
          </button>
        </div>
        <div class="marketplace-card col mt-4" v-if="product.company">
          <div
            class="seller-info d-flex justify-content-between align-items-center"
            @click="goToCompany(company.inn)"
          >
            <p class="mb-0">{{ product.company_name }}</p>
            <svg
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.332928 0.292786C0.122023 0.480314 0.0035429 0.734622 0.0035429 0.999786C0.0035429 1.26495 0.122023 1.51926 0.332928 1.70679L5.90168 6.65679L0.332928 11.6068C0.128 11.7954 0.0146065 12.048 0.01717 12.3102C0.0197334 12.5724 0.138048 12.8232 0.346632 13.0086C0.555216 13.194 0.83738 13.2992 1.13235 13.3015C1.42732 13.3037 1.7115 13.2029 1.92368 13.0208L8.2878 7.36379C8.49871 7.17626 8.61719 6.92195 8.61719 6.65679C8.61719 6.39162 8.49871 6.13731 8.2878 5.94979L1.92368 0.292786C1.71271 0.105315 1.42661 0 1.1283 0C0.829993 0 0.543897 0.105315 0.332928 0.292786Z"
                fill="#3E3E3E"
              />
            </svg>

            <!-- <button>Написать</button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="marketplace-description mt-4">
      <h3>Описание</h3>
      <p class="mt-4" v-html="product.description"></p>
    </div>

    <div class="divider"></div>
    <div class="marketplace-specs-section d-md-flex mt-4">
      <div class="specs col-md-6">
        <h3>Характеристика</h3>
        <table class="mt-4 w-100">
          <tr>
            <td>Артикул</td>
            <td></td>
          </tr>
          <tr>
            <td>Тип товара</td>
            <td>{{ product.category?.name }}</td>
          </tr>
          <tr>
            <td>Производитель</td>
            <td>{{ product.company_name }}</td>
          </tr>
          <tr>
            <td>Материал</td>
            <td></td>
          </tr>
        </table>
      </div>
      <div class="seller-details col-md-6 mb-4 mt-md-0 mt-4">
        <h3>О продавце</h3>
        <table class="mt-4 w-100">
          <tr>
            <td>Название</td>
            <td>{{ company?.name }}</td>
          </tr>
          <tr>
            <td>Генеральный директор</td>
            <td>{{ company?.directors[0]?.fio }}</td>
          </tr>
          <tr>
            <td>ОГРН</td>
            <td>{{ company?.ogrn }}</td>
          </tr>
          <tr>
            <td>ИНН</td>
            <td>{{ company?.inn }}</td>
          </tr>
          <tr>
            <td>Адрес</td>
            <td>{{ company?.legal_adress?.adress }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="divider"></div>

    <div class="reviews-section">
      <!-- <div class="tabs"> -->
      <span class="tab active" style="color: #0066ce">Отзывы о товаре</span>
      <!-- <span class="tab">Отзывы о поставщике</span>
      <span class="tab">Вопросы о товаре</span> -->
      <!-- </div> -->
      <div class="reviews">
        <template v-if="product?.comments">
          <div
            class="review d-flex"
            v-for="comment in product.comments"
            :key="comment.id"
          >
            <img
              :src="comment.user.avatar"
              alt="avatar"
              class="review-avatar"
            />
            <div class="ms-3">
              <div class="d-flex">
                <p class="review-author bold-text">
                  {{ comment.user.full_name }}
                </p>

                <div class="review-rating ms-2">
                  <span
                    v-for="star in comment.stars"
                    :key="star"
                    class="stars me-2"
                    >★</span
                  >
                </div>
              </div>
              <p class="review-date">{{ formatDate(comment.time_create) }}</p>
              <p class="review-text">{{ comment.content }}</p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from "vue";
import MarketplaceService from "@/services/marketplace";
import ChatRoomService from "@/services/chatRoom";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";

// import { Swiper, SwiperSlide } from "swiper/vue"; // Correct path for Swiper 8+
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";

import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "MarketplaceItem",
  components: {
    Swiper,
    SwiperSlide,
    // Pagination,
    // Navigation,
    // Autoplay,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const product = ref(null);
    const company = ref(null);
    const productAdded = ref(false);
    const mainImg = ref(null);

    const likedProduct = ref(false);
    const selectRating = ref(0);
    const commentText = ref("");
    const parentComment = ref(null);
    const isLoggedIn = computed(() => store.state.auth.status.loggedIn);

    const clickLike = async () => {
      if (isLoggedIn.value) {
        if (!likedProduct.value) {
          await MarketplaceService.addToFavorite(product.value.id);
          likedProduct.value = true;
        } else {
          await MarketplaceService.removeFromFavorite(product.value.id);
          likedProduct.value = false;
        }
      } else {
        openModal();
      }
    };

    const sendComment = () => {
      if (
        (selectRating.value !== 0 || parentComment.value) &&
        commentText.value !== ""
      ) {
        MarketplaceService.sendComment(
          selectRating.value,
          commentText.value,
          product.value.id,
          parentComment.value
        );
        MarketplaceService.show(route.params.id).then((res) => {
          product.value = res;
        });
      }
    };

    const createChat = async () => {
      if (isLoggedIn.value) {
        const room = await ChatRoomService.addRoom({
          product: product.value.id,
        });
        store.dispatch("chats/SelectChat", {
          selectId: room.id,
          selectSlug: room.slug,
        });

        router.push("/profile/messages");
      } else {
        openModal();
      }
    };

    const addToCart = async () => {
      if (isLoggedIn.value) {
        await MarketplaceService.addToBasket(product.value.id, 1);
        store.dispatch("user/getCartItems");

        productAdded.value = true;
        setTimeout(() => {
          productAdded.value = false;
        }, 1000);
      } else {
        openModal();
      }
    };

    const openModal = () => {
      const loginModal = new Modal(document.getElementById("authModal"));
      loginModal.show();
    };

    const goToCompany = () => {
      router.push({
        name: "Company",
        params: { id: product.value.company },
      });
    };

    const formatPrice = (price) => {
      return new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(price);
    };

    onMounted(async () => {
      await MarketplaceService.show(route.params.id).then((res) => {
        product.value = res;
        likedProduct.value = product.value.is_liked;
        if (product.value.images?.length) {
          mainImg.value = product.value.images[0]?.image;
        } else {
          mainImg.value = require("@/assets/images/logo_info.svg");
        }
      });
      if (product.value) {
        const companyId = product.value.company;
        if (companyId) {
          company.value = await store.dispatch("companies/getById", companyId);
        }
      }
    });

    const formatDate = (date) => {
      return moment(date).format("DD.MM.YYYY");
    };

    return {
      company,
      product,
      likedProduct,
      productAdded,
      mainImg,
      goToCompany,
      addToCart,
      clickLike,
      sendComment,
      formatDate,
      createChat,
      formatPrice,
      Pagination,
      Navigation,
      Autoplay,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/marketplace.scss";

.swiper-slide-img {
  width: 100%;
  height: 20rem;
  object-fit: contain;
}
.swiper-button-next {
  color: #3e3e3e;

  &::after {
    content: "";
    color: #3e3e3e;
    font-size: 20px;
    border-color: #3e3e3e;
  }
}

.swiper-button-prev {
  color: #3e3e3e;

  &::after {
    content: "";
    color: #3e3e3e;
    font-size: 20px;
    border-color: #3e3e3e;
  }
}
</style>
