import { Module } from "vuex";
import UserService from "../services/user";
import MarketplaceService from "@/services/marketplace";
import { Cart } from "@/services/marketplace";

interface User {
  id: string;
}
interface RootState {
  companyData: any;
  loading: boolean;
  error: any;
}
interface UserState {
  user: User | null;
  cartItems: number;
  myProducts: any;
}

const state: UserState = {
  user: JSON.parse(localStorage.getItem("user") || "null"),
  cartItems: 0,
  myProducts: 0,
};

export const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  actions: {
    async me({ commit }) {
      try {
        const user = await UserService.me();
        commit("setUser", user);

        // const cart = await MarketplaceService.getCart();
        // commit("setCartItems", cart);

        // const products = await MarketplaceService.companyRepresentative(
        //   user.id
        // );
        // commit("setMyProducts", products.result.length);

        return user;
      } catch (error) {
        commit("meFailure");
        throw error;
      }
    },
    async getCartItems({ commit }) {
      try {
        const cart = await MarketplaceService.getCart();
        commit("setCartItems", cart);
        return cart;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
  mutations: {
    setCartItems(state, items: Cart) {
      state.cartItems = items.baskets.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
    },
    setMyProducts(state, products: number) {
      state.myProducts = products;
    },
    setUser(state, user: User) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    meFailure(state) {
      state.user = null;
    },
  },
};
