<template>
  <header class="header main">
    <div class="d-flex container justify-content-between align-items-center">
      <router-link to="/" class="logo">
        <img src="@/assets/images/logo.svg" alt="Info Global Logo" />
      </router-link>
      <Burger />
      <nav class="navigation">
        <ul>
          <li><router-link to="/">Главная</router-link></li>
          <li><router-link to="/marketplace">Маркетплейс</router-link></li>
          <li>
            <router-link :to="{ path: '/', hash: '#pricing-section' }"
              >Тариф</router-link
            >
          </li>
          <li>
            <router-link :to="{ path: '/', hash: '#about-section' }"
              >Проект</router-link
            >
          </li>
          <li>
            <router-link :to="{ path: '/', hash: '#footer-section' }"
              >Реквизиты</router-link
            >
          </li>
          <li><router-link to="/blog">Блог</router-link></li>
        </ul>
      </nav>
      <Translator />
      <div class="login d-flex">
        <a
          @click.prevent="openAuthModal"
          v-if="!isLoggedIn || !currentUser"
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#authModal"
          >Войти <img src="@/assets/images/user-icon.png" alt="User Icon"
        /></a>
        <template v-else>
          <router-link to="/profile/info" class="username">{{
            currentUser.full_name
          }}</router-link>
          <img
            v-if="currentUser?.avatar"
            :src="currentUser?.avatar"
            class="ms-2 photo"
            @click="router.push('/profile/info')"
          />
          <img
            v-else
            src="@/assets/images/user-icon.png"
            class="ms-2 photo"
            @click="router.push('/profile/info')"
          />
        </template>
      </div>
    </div>
  </header>
  <AuthModal />
  <NewPasswordModal ref="newPasswordModalRef" />
</template>

<script>
import { defineComponent, computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import Burger from "./Burger.vue";
import AuthModal from "../components/Modals/AuthModal.vue";
import NewPasswordModal from "../components/Modals/NewPasswordModal.vue";
import { useRouter, useRoute } from "vue-router";
import Translator from "./Translator.vue";

export default defineComponent({
  components: { Burger, AuthModal, NewPasswordModal, Translator },
  name: "Header",
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.state.auth.status.loggedIn);
    const currentUser = computed(() => store.state.user.user);
    const router = useRouter();
    const route = useRoute();
    const newPasswordModalRef = ref(null);

    const openPasswordModal = () => {
      newPasswordModalRef.value.openModal();
    };
    onMounted(() => {
      if (newPasswordModalRef.value && route.query.uid) {
        openPasswordModal();
      }
    });

    return {
      currentUser,
      isLoggedIn,
      router,
      newPasswordModalRef,
      openPasswordModal,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/Header.scss";
@import "@/assets/styles/media.scss";
</style>
