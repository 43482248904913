<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card finances">
      <div class="card-header">
        <h5 class="card-title mb-0">Финансы</h5>
      </div>

      <div class="d-flex navs">
        <ul class="nav nav-tabs">
          <li>
            <a
              class="active grey-button"
              id="analyzesmall-tab"
              data-bs-toggle="tab"
              href="#tab-analyzesmall"
              role="tab"
              aria-controls="tab-analyzesmall"
              aria-selected="true"
              >Анализ</a
            >
          </li>
          <li class="ms-md-4">
            <a
              class="grey-button"
              id="report-tab"
              data-bs-toggle="tab"
              href="#tab-report"
              role="tab"
              aria-controls="tab-report"
              aria-selected="false"
              >Бухгалтерская отчетность</a
            >
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content" id="myTabContent">
          <!-- Первый таб -->
          <div
            class="tab-pane fade show active"
            id="tab-analyzesmall"
            role="tabpanel"
            aria-labelledby="analyzesmall-tab"
            v-if="html"
          >
            <div class="row mt-4">
              <div class="col-md-5">
                <ul class="info-list">
                  <li
                    v-for="(factor, index) in factors"
                    :key="index"
                    class="d-flex flex-row border-bottom align-items-center pt-4 justify-content-between"
                  >
                    <span class="info-value w-auto">{{ factor.title }}</span>
                    <span class="info-bold">{{ factor.text }}</span>
                  </li>
                </ul>
              </div>
              <div class="col-md-5">
                <BarChart :chartData="chartData" :chartOptions="chartOptions" />
              </div>
            </div>
            <table
              class="table table-bordered"
              style="border: 1px solid #efefef"
            >
              <template v-for="(factor, index) in financeFactors" :key="index">
                <thead v-if="factor.title">
                  <tr>
                    <th style="background-color: #f7f9fc">
                      {{ factor.title }}
                    </th>
                  </tr>
                </thead>
                <tbody v-else>
                  <tr>
                    <td>
                      {{ factor.text }}
                    </td>
                    <td>{{ factor.description }}</td>
                  </tr>
                </tbody>
              </template>
            </table>
          </div>

          <!-- Второй таб -->
          <div
            class="tab-pane fade"
            id="tab-report"
            role="tabpanel"
            aria-labelledby="report-tab"
          >
            <!-- Фильтр и кнопка "Скачать" -->
            <div
              class="card d-flex flex-row justify-content-between align-items-center col-10 px-4 py-4 mt-4"
              v-if="finances.links && finances.links['Отчет']"
            >
              <p class="mb-0">Бухгалтерская отчетность</p>
              <a
                :href="finances.links['Отчет']['2023']"
                class="btn-primary btn-small"
                target="_blank"
                >Скачать</a
              >
            </div>

            <!-- Бухгалтерский баланс -->
            <h5 class="finances-title mt-4 pt-4 pb-2">Бухгалтерский баланс</h5>
            <div class="card mt-4 inner-card">
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <!-- <BarChart /> -->
                  </div>
                </div>
                <div class="table-container">
                  <table class="table table-bordered colored">
                    <thead>
                      <tr>
                        <th scope="col" class="text-center">Код</th>
                        <th
                          scope="col"
                          class="text-center"
                          v-for="year in years"
                          :key="year"
                        >
                          {{ year }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="code in codes" :key="code">
                        <td>{{ code }}</td>
                        <td v-for="year in years" :key="year">
                          {{ getSumOtch(year, code) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from "vue";
import BarChart from "./cards/Diagrams/BarChart.vue";

export default defineComponent({
  name: "Finances",
  props: {
    html: String,
    finances: Object,
  },
  components: {
    BarChart,
  },
  setup(props) {
    const isOpen = ref(null);

    // Данные для графика
    const chartData = {
      labels: props.finances.finances_years?.map((item) => item.year),
      datasets: [
        {
          label: "Region A",
          data: props.finances.finances_years?.map((item) =>
            item.amount.reduce((sum, num) => sum + parseFloat(num) / 1000, 0)
          ),
          backgroundColor: "#86C2FF4D",
          borderColor: "#86C2FF",
          borderWidth: 1,
          borderRadius: 5,
          barPercentage: 1,
          categoryPercentage: 0.8,
        },
      ],
    };

    // Опции для графика
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          // position: "top",
        },
        title: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.dataset.label + ": " + context.parsed.y;
            },
          },
        },
      },
      layout: {
        padding: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
          },
        },
        y: {
          display: true,
          grid: {
            display: true,
          },
          title: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        r: {
          ticks: {
            callback: function () {
              return null;
            },
          },
        },
      },
    };
    const isCollapsed = ref(true);
    const factors = ref([]);
    const financeFactors = ref([]);
    const years = computed(() =>
      Object.keys(props.finances.additional_finances)
    );

    const codes = computed(() => {
      const codesSet = new Set();
      Object.values(props.finances.additional_finances).forEach((yearData) => {
        Object.keys(yearData).forEach((code) => codesSet.add(code));
      });
      return Array.from(codesSet).sort();
    });

    const getSumOtch = (year, code) => {
      return (
        formatPrice(props.finances.additional_finances[year][code]?.СумОтч) ||
        null
      );
    };

    function toggleCollapse() {
      isCollapsed.value = !isCollapsed.value;
    }

    const formatPrice = (price) => {
      if (price === null || price === undefined) {
        return "—";
      }
      return new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(price);
    };

    const getParsing = () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(props.html, "text/html");
      const finances = doc.getElementById("finances");
      if (finances) {
        const table = finances.querySelector("table");
        const table1 = finances.querySelectorAll("table")[1];
        const tr = table?.querySelectorAll("tr");
        const tr1 = table1?.querySelectorAll("tr");

        if (tr?.length) {
          tr?.forEach((stroke, index) => {
            const tdFirst = stroke.querySelector("td:nth-child(1)");
            const tdSecond = stroke.querySelector("td:nth-child(2) span");
            if (tdFirst?.textContent && tdFirst?.textContent !== "Активы") {
              const tableItemObject = {
                id: index + 1,
                title: tdFirst.textContent,
                text: tdSecond.textContent,
              };
              factors.value.push(tableItemObject);
            }
          });

          if (tr1?.length) {
            tr1?.forEach((stroke, index) => {
              const th = stroke.querySelector("th");
              const tdFirst = stroke.querySelector("td:nth-child(1)");
              const tdSecond = stroke.querySelector("td:nth-child(2) span");
              const tableItemObject = {
                id: index + 1,
                title: th?.textContent || null,
                text: tdFirst?.textContent.replace(/\?/g, "") || null,
                description: tdSecond?.textContent || null,
              };
              financeFactors.value.push(tableItemObject);
            });
          }
        }
      }
    };
    if (props.html) {
      getParsing();
    }

    return {
      isOpen,
      chartData,
      chartOptions,
      factors,
      financeFactors,
      years,
      codes,
      getSumOtch,
      formatPrice,
      toggleCollapse,
    };
  },
});
</script>

<style scoped lang="scss">
.table-container {
  width: 100%;
  height: 500px;
  overflow-x: auto;
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  // min-width: 1000px;

  th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff !important;
  }
}
</style>
