<template>
  <div class="col-12 connections py-4 mb-4">
    <div class="card finances">
      <div class="card-header">
        <h5 class="card-title mb-0">Арбитражные дела</h5>
      </div>

      <div class="d-flex navs">
        <ul class="nav nav-tabs">
          <li>
            <a
              class="active grey-button"
              id="deals-tab"
              data-bs-toggle="tab"
              href="#tab-deals"
              role="tab"
              aria-controls="tab-deals"
              aria-selected="true"
              >Дела</a
            >
          </li>
          <li class="ms-4">
            <a
              class="grey-button"
              id="analyze-tab"
              data-bs-toggle="tab"
              href="#tab-analyze"
              role="tab"
              aria-controls="tab-analyze"
              aria-selected="false"
              >Статистика</a
            >
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content" id="myTabContent">
          <!-- Первый таб -->
          <div
            class="tab-pane fade show active"
            id="tab-deals"
            role="tabpanel"
            aria-labelledby="deals-tab"
          >
            <ArbitrationDeals :cases="cases || []" />
          </div>

          <!-- Второй таб -->
          <div
            class="tab-pane fade"
            id="tab-analyze"
            role="tabpanel"
            aria-labelledby="analyze-tab"
          >
            <ArbitrationStatistics style="filter: blur(5px)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import ArbitrationDeals from "./ArbitrationDeals.vue";
import ArbitrationStatistics from "./ArbitrationStatistics.vue";

export default defineComponent({
  name: "Arbitration",
  components: {
    ArbitrationDeals,
    ArbitrationStatistics,
  },
  props: {
    cases: Array,
  },
  setup(props) {
    // Данные для графика
    const chartData = {
      labels: [
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
      ],
      datasets: [
        {
          label: "Region A",
          data: [10, 20, 15, 25, 30, 35, 40, 35, 20, 15, 38, 22, 35],
          backgroundColor: "#86C2FF4D",
          borderColor: "#86C2FF",
          borderWidth: 1,
          borderRadius: 5,
          // Создаем отступы для столбцов
          barPercentage: 1,
          categoryPercentage: 0.8,
        },
        {
          label: "Region B",
          data: [12, 18, 20, 22, 28, 32, 38, 10, 20, 15, 25, 32, 35],
          backgroundColor: "#B17BFF4D",
          borderColor: "#B17BFF",
          borderWidth: 1,
          borderRadius: 5,
          // Создаем отступы для столбцов
          barPercentage: 1,
          categoryPercentage: 0.8,
        },
      ],
    };

    // Опции для графика
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.dataset.label + ": " + context.parsed.y;
            },
          },
        },
      },
      layout: {
        padding: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
          },
        },
        y: {
          display: true, // Скрывает ось X
          grid: {
            display: true, // Скрывает сетку по оси X
          },
          title: {
            display: false, // Скрывает заголовок оси X
          },
          ticks: {
            display: false, // Скрывает заголовок оси X
          },
        },
        r: {
          ticks: {
            callback: function (value) {
              return value.toLocaleString("en-US", {
                minimumFractionDigits: Math.min(Math.max(0, 100), 20),
              });
            },
          },
        },
      },
    };
    const isCollapsed = ref(true);

    function toggleCollapse() {
      isCollapsed.value = !isCollapsed.value;
    }

    return {
      toggleCollapse,
      chartData,
      chartOptions,
    };
  },
});
</script>
