import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import setup from "./services/setup";
import AuthService from "./services/auth";

import "@/assets/styles/main.scss";
import "@/assets/styles/fonts.scss";
import "@/assets/styles/media.scss";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.css";

setup();
// Попытка автологина при запуске приложения
AuthService.autoLogin().catch((error) => {
  console.log("Auto login failed", error);
});

createApp(App).use(store).use(router).mount("#app");
