<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="card-title mb-0">Анализ надежности</h5>
        <span class="status ms-4" :class="getClass(company?.status?.name)">{{
          company?.status?.name
        }}</span>
      </div>

      <div class="px-4">
        <Gauge :pros="pros" :cons="cons" />
      </div>
      <ul class="info-list">
        <li
          class="flex flex-row justify-content-between align-items-center border-bottom"
        >
          <span class="info-value success">Положительные</span
          ><span class="info-bold">{{ pros }}</span>
        </li>
        <!-- <li
          class="flex flex-row justify-content-between align-items-center border-bottom pt-2"
        >
          <span class="info-value danger">Требуют внимания</span
          ><span class="info-bold">2</span>
        </li> -->
        <li
          class="flex flex-row justify-content-between align-items-center border-bottom pt-2"
        >
          <span class="info-value decline">Отрицательные</span>
          <span class="info-bold">{{ cons }}</span>
        </li>
      </ul>
      <button
        class="btn-primary btn-small d-block ms-auto mt-2"
        @click="emitChangeTab('tab-reliability')"
      >
        Подробнее
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Gauge from "./Diagrams/Gauge.vue";

export default defineComponent({
  name: "AnalyzeInfo",
  props: {
    activeTab: String,
    company: Object,
  },
  components: { Gauge },
  setup(props, { emit }) {
    const pros = ref(null);
    const cons = ref(null);
    const getParsing = () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        props.company.html_checko,
        "text/html"
      );

      const rating = doc.getElementById("rating");
      const ul = rating?.querySelector("ul");
      const firstLi =
        ul?.querySelector("li:first-child")?.querySelector("span")
          ?.textContent || ul?.querySelector("li:first-child")?.textContent;
      const lastLi =
        ul?.querySelector("li:last-child")?.querySelector("span")
          ?.textContent || ul?.querySelector("li:last-child")?.textContent;

      if (firstLi && lastLi) {
        const wordsPros = firstLi?.trim().split(" ");
        const wordsCons = lastLi?.trim().split(" ");

        pros.value = wordsPros[wordsPros?.length - 1].replace(/[()]/g, "");
        cons.value = wordsCons[wordsCons?.length - 1].replace(/[()]/g, "");
      }
    };

    const emitChangeTab = (newTab) => {
      emit("change-tab", newTab);
    };

    const getClass = (status) => {
      if (status === "Действует") {
        return "active";
      } else if (status === "Не действует") {
        return "inactive";
      } else {
        return "liquidated";
      }
    };
    if (props.company.html_checko) {
      getParsing();
    }
    return {
      pros,
      cons,
      emitChangeTab,
      getClass,
    };
  },
});
</script>
