<template>
  <div class="cart profile addProduct">
    <div class="card">
      <div class="card-body">
        <form class="form row" @keydown.enter.prevent>
          <div class="col-md-7">
            <div class="input mt-3">
              <label for="company">ИНН компании</label>
              <div class="input-box d-md-flex mt-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Введите ИНН или Название"
                  v-model="searchQuery"
                  @input="onSearch"
                  @focus="isFocused = true"
                />
                <ul
                  class="list-group position-absolute"
                  v-if="searchQuery && isFocused"
                >
                  <div
                    class="search-type"
                    v-if="searchResultsIP.length && searchResultsINN?.length"
                  >
                    Искать по:
                    <button
                      :class="{
                        select: selectMode === 'inn' || selectMode === 'start',
                      }"
                      class="ms-2 btn-small"
                      @click="selectMode = 'inn'"
                      v-if="searchResultsINN.length"
                    >
                      Компаниям
                    </button>
                    <button
                      :class="{ select: selectMode === 'ip' }"
                      class="ms-4 btn-small"
                      @click="selectMode = 'ip'"
                      v-if="searchResultsIP.length"
                    >
                      ИП
                    </button>
                  </div>
                  <template
                    v-if="
                      (selectMode === 'inn' || selectMode === 'start') &&
                      searchResultsINN.length &&
                      !isSearchLoading
                    "
                  >
                    <li
                      v-for="company in searchResultsINN"
                      :key="company['ИНН']"
                      class="list-group-item"
                      @click="selectCompany(company['ИНН'])"
                    >
                      <h5>{{ company["НаимСокр"] || company["ФИО"] }}</h5>
                      <p><span>ИНН:</span> {{ company["ИНН"] }}</p>
                    </li>
                  </template>
                  <template
                    v-if="
                      searchResultsIP.length &&
                      (selectMode === 'ip' || selectMode === 'start') &&
                      !isSearchLoading
                    "
                  >
                    <li
                      v-for="company in searchResultsIP"
                      :key="company['ИНН']"
                      class="list-group-item"
                      @click="selectCompany(company['ИНН'])"
                    >
                      <h5>{{ company["НаимСокр"] || company["ФИО"] }}</h5>
                      <p><span>ИНН:</span> {{ company["ИНН"] }}</p>
                    </li>
                  </template>
                  <template v-else>
                    <li class="list-group-item"><div class="loader"></div></li>
                  </template>
                </ul>
              </div>
            </div>
            <div class="input mt-3">
              <label for="inputGroupFile01">Паспорт</label>
              <input
                ref="fileInput"
                @change="onFileChange($event, 'passport')"
                type="file"
                class="form-control mt-2"
                style="height: fit-content"
                id="inputGroupFile01"
              />
            </div>

            <div class="input mt-3">
              <label for="inputGroupFile01">Фото или скан ТД</label>
              <input
                ref="fileInput"
                @change="onFileChange($event, 'contract')"
                type="file"
                class="form-control mt-2"
                style="height: fit-content"
                id="inputGroupFile01"
              />
            </div>
            <div class="input mt-3">
              <label for="inputGroupFile01"
                >Заявление в свободной форме <br /><span
                  >Я, ФИО, прошу привязать меня к данной компании….</span
                ></label
              >
              <input
                ref="fileInput"
                @change="onFileChange($event, 'document')"
                type="file"
                class="form-control mt-2"
                style="height: fit-content"
                id="inputGroupFile01"
              />
            </div>
            <div class="error" role="alert">{{ error }}</div>
            <button class="black-button mt-4" @click.prevent="addProduct">
              <template v-if="!isLoading"> Отправить заявку </template>
              <div v-else class="loader mx-auto my-0"></div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import CompanyService from "@/services/company";
import { useDebounceFn } from "@vueuse/core";

const router = useRouter();
const store = useStore();
const loggedIn = computed(() => store.state.auth.status.loggedIn);
const currentUser = ref({});
const fileInput = ref(null);
const error = ref(null);
const isLoading = ref(false);

const formData = ref({
  company: "",
  passport: "",
  contract: "",
  document: "",
});
const companies = ref([]);

const isFocused = ref(false);
const searchQuery = ref("");
const isSearchLoading = ref(false);
const selectMode = ref("start");
const searchResultsINN = ref([]);
const searchResultsIP = ref([]);

onMounted(async () => {
  if (!loggedIn.value) {
    router.push("/");
  } else {
    const user = await store.dispatch("user/me");
    currentUser.value = user;
    CompanyService.all().then((res) => {
      companies.value = res.result;
    });
  }
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

const searchCompany = useDebounceFn(async () => {
  isFocused.value = true;
  if (searchQuery.value) {
    store
      .dispatch("companies/searchCompanies", searchQuery.value)
      .then((companies) => {
        selectMode.value = "start";
        searchResultsIP.value = [];
        searchResultsINN.value = [];
        searchResultsIP.value = companies[0]["Записи"] || companies;
        if (companies?.at(1)) {
          searchResultsINN.value = companies?.at(1)["Записи"];
        }
        isSearchLoading.value = false;
      });
  }
}, 1000);

// Добавление обработчика клика вне списка
const handleClickOutside = (event) => {
  const inputBox = document.querySelector(".input-box"); // Обновите селектор, если нужно
  const listGroup = document.querySelector(".list-group"); // Обновите селектор, если нужно

  if (
    inputBox &&
    !inputBox.contains(event.target) &&
    !listGroup.contains(event.target)
  ) {
    closeList();
  }
};

// Функция для закрытия списка
const closeList = () => {
  isFocused.value = false;
  // searchQuery.value = ""; // Можно также очистить запрос, если нужно
};

// Функция для выбора компании
const selectCompany = (inn) => {
  formData.value.company = inn; // Обновляем значение компании
  searchQuery.value = inn;
  isFocused.value = false; // Закрываем список
};

const onSearch = () => {
  formData.value.company = null;
  isSearchLoading.value = true;
  searchCompany();
};

const onFileChange = (event, name) => {
  if (event.target.files && event.target.files[0]) {
    const file = event.target.files[0];
    formData.value[name] = file;
  }
};

const addProduct = async () => {
  const newProduct = new FormData();
  // Добавляем текстовые данные в FormData
  newProduct.append("company", formData.value.company);

  // Добавляем файлы
  if (formData.value.passport) {
    newProduct.append("passport", formData.value.passport);
  }
  if (formData.value.contract) {
    newProduct.append("contract", formData.value.contract);
  }
  if (formData.value.document) {
    newProduct.append("document", formData.value.document);
  }

  // Проверка всех обязательных полей
  if (
    formData.value.company &&
    formData.value.passport &&
    formData.value.contract &&
    formData.value.document
  ) {
    try {
      isLoading.value = true;
      // Создание нового продукта
      await CompanyService.addCompany(newProduct);
      isLoading.value = false;
      router.push("/profile/info");
    } catch (e) {
      error.value = "Ошибка при сохранении данных";
    }
  } else {
    error.value = "Заполните все поля";
  }
};
</script>

<style scoped lang="scss">
label span {
  color: #a1a1a1;
  font-size: 0.9rem;
}
.form-control {
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 150px;
}
button {
  min-width: 13rem;
}
.input-box {
  position: relative;
  button {
    min-width: unset;
  }
  .form-control {
    height: 38px;
    border-radius: 10px;
  }

  .list-group {
    .list-group-item {
      h5 {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
</style>
